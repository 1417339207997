/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import CustomUsageColumnChartConfig from 'embercom/lib/billing/usage/custom-usage-default-column-chart-config';
import { isPresent } from '@ember/utils';
import { HORIZONTAL_BAR_TYPES } from 'embercom/models/reporting/custom/visualization-options';
import { buildHorizontalBarChartConfig } from 'embercom/lib/reporting/custom/horizontal-bar-chart-helper';
import { STACKING_TYPES } from 'embercom/lib/reporting/custom/view-config-builder';
import PercentFormatter from 'embercom/lib/reporting/flexible/formatters/percent';

import SerieschartBuilder from 'embercom/lib/reporting/flexible/serieschart-builder';

const MAX_LABELS = 12;
const MAX_LABELS_FOR_NOMINAL_AXIS = 20;

export default class CustomUsageSerieschartBuilder extends SerieschartBuilder {
  buildTheme(visualizationType = undefined) {
    let config = new CustomUsageColumnChartConfig(this.range.timezone);

    config.setChartType(this.chartType);
    config.setHeight(this.viewConfig.heightType || 'flexible');
    config.setXAxisType(this.isXAxisTemporal ? 'datetime' : 'category');

    if (this.isXAxisTemporal) {
      config.setXAxisTickInterval(this.xAxisTickInterval);
    }

    if (this.viewConfig.grouping?.tickPositioner) {
      config.setXAxisTickPositioner(this.viewConfig.grouping.tickPositioner);
    }

    if (
      this.xAxisLabelCount > MAX_LABELS &&
      this.isXAxisTemporal &&
      this.viewConfig.skipLabelsForLargeSeries
    ) {
      config.setStep(this._getStepForConfig());
    }

    config.setYAxisTickInterval(this.yAxisTickInterval);
    config.setYAxisRanges(this.viewConfig.yAxis?.min, this.yAxisMax);
    config.setTooltipFormatter(this.viewConfig.tooltipFormatter || this.tooltipFormatter);

    if (this.isXAxisTemporal) {
      config.setXAxisFormatter(this.temporalLabelFormatter);
      // last X axis label is clipped without the extra spacing (default is 10)
      config.setSpacingRight(15);
    } else if (
      this.totalNumberOfColumns >= MAX_LABELS_FOR_NOMINAL_AXIS &&
      this.dataConfig.xAxis?.data?.limit >= MAX_LABELS_FOR_NOMINAL_AXIS
    ) {
      config.setXAxisFormatter(this.nominalLabelFormatter);
    }

    // Using formatData make it work, but I think it cries out the need to split formatter for axis and tooltip
    config.setYAxisFormatter(({ value }) => this.formatter.formatAxis(value));

    this._setConsistentColors(config);

    config.setMinimumPointLength(4);

    if (this.stacking) {
      config.setStacking(this.stacking, this.chartType);
    }
    this._enableHoverState(config);

    if (
      this.viewConfig.labelStyleOverrides &&
      isPresent(this.viewConfig.labelStyleOverrides.fontSize)
    ) {
      config.setXAxisFontSize(this.viewConfig.labelStyleOverrides.fontSize);
    }

    if (this.viewConfig.useDarkTooltips) {
      config.useDarkTooltips();
    }

    if (this.viewConfig.disableLegend) {
      config.disableLegend();
    }

    if (this.viewConfig.colorByPoint) {
      config.setColorByPoint(this.viewConfig.colorByPoint);
    }

    if (HORIZONTAL_BAR_TYPES.includes(visualizationType)) {
      let horizontalBarChartConfig = buildHorizontalBarChartConfig(
        this.dataConfig.series,
        config.config,
      );
      config.config = {
        ...config.config,
        ...horizontalBarChartConfig,
      };
    }

    if (this.viewConfig.showDataLabels) {
      config.config.plotOptions.series.dataLabels = this.dataLabels;
      config.setDataLabelFormatter(this.dataLabelFormatter);
    }

    if (isPresent(this.targetValue)) {
      let percentageFormatter = new PercentFormatter();
      let formattedValue =
        this.viewConfig.columnChart?.stacking === STACKING_TYPES.PERCENT
          ? percentageFormatter.formatAxis(this.targetValue)
          : this.formatter.formatAxis(this.targetValue);

      config.setTargetLine(this.targetValue, formattedValue);
    }

    return config.config;
  }
}
