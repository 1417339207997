/* RESPONSIBLE TEAM: team-ai-chatbot */

import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';

const CASINGS_ENFORCED = {
  AI: / ai /gi,
  'AI Agent': / ai agent /gi,
  'AI Category': /ai category/gi,
};

export interface Signature {
  Args: {
    Positional: [string];
  };
  Return: string;
}

/**
 * Enforce AI wording casing on a specific words or set of characters
 * This is meant to be used only on predicates as they are not localized.
 * Be careful, for localized strings the translation may be different. For example in French AI is IA so this won't work
 */
export default class EnforceAiCasing extends Helper<Signature> {
  @service declare intl: IntlService;

  compute([str]: [string]): string {
    let res = str;

    Object.entries(CASINGS_ENFORCED).forEach(([pattern, regex]) => {
      res = res.replaceAll(regex, ` ${pattern} `);
    });

    return res;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'enforce-ai-casing': typeof EnforceAiCasing;
  }
}
