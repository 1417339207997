/* RESPONSIBLE TEAM: team-frontend-tech */
export function navigatorInfo() {
  try {
    return {
      'device.cpu_cores': navigator.hardwareConcurrency,
      'device.connection_type': navigator.connection?.effectiveType,
      'device.downlink': navigator.connection?.downlink,
      'device.memory': navigator.deviceMemory,
      'device.rtt': navigator.connection?.rtt,
      'device.online': navigator.onLine,
      'device.language': navigator.language,
    };
  } catch {
    // Do nothing, navigator could be a dead object
  }
}
