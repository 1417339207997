/* RESPONSIBLE TEAM: team-growth-opportunities */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
let AddressSettings = {
  CA: {
    postCodeLabel: 'Postal code', // deprecated bare string in favour of below TranslationKey field
    postCodeLabelTranslationKey: 'signup.teams.start.form.postal_code_label',
    showState: true,
    stateLabel: 'Province', // deprecated bare string in favour of below TranslationKey field
    stateLabelTranslationKey: 'signup.teams.start.form.province_label',
    maxLengths: {
      streetAddress: 50,
      city: 50,
      postcode: 11,
    },
  },
  US: {
    postCodeLabel: 'ZIP code', // deprecated bare string in favour of below TranslationKey field
    postCodeLabelTranslationKey: 'signup.teams.start.form.zip_code_label',
    postCodeRegex: /\d{5}/,
    showState: true,
    stateLabel: 'State', // deprecated bare string in favour of below TranslationKey field
    stateLabelTranslationKey: 'signup.teams.start.form.state_label',
    maxLengths: {
      streetAddress: 50,
      city: 50,
      postcode: 5,
    },
  },
  default: {
    postCodeLabel: 'Postal code', // deprecated bare string in favour of below TranslationKey field
    postCodeLabelTranslationKey: 'signup.teams.start.form.postal_code_label',
    showState: false,
    maxLengths: {
      streetAddress: 50,
      city: 50,
      postcode: 11,
    },
  },
};

export default AddressSettings;
