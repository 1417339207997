/* RESPONSIBLE TEAM: team-reporting */
import PALETTE from '@intercom/pulse/lib/palette';
import Tooltip from 'embercom/lib/reporting/flexible/tooltip';
import { copy } from 'ember-copy';
import DefaultHighchartConfig from 'embercom/lib/reporting/flexible/default-highchart-config';

const config = {
  legend: {
    enabled: true,
    align: 'center',
    margin: 27,
    layout: 'horizontal',
    labelFormat: '{userOptions.legendLabel}',
    itemHoverStyle: { color: null },
    maxHeight: 70,
  },
  chart: {
    spacingLeft: 3,
    spacingBottom: 0,
  },
  xAxis: {
    lineColor: 'transparent',
    type: 'datetime',
    dateTimeLabelFormats: {
      hour: '%l%p',
      day: '%b %e',
      week: '%b %e',
    },
    offset: 7,
    tickLength: 0,
    labels: { style: {} },
  },

  yAxis: {
    softMax: 2, // Used in cases where we have no data, the chart defaults to yAxis max so it looks visually "correct".
    allowDecimals: false,
    gridLineColor: '#f2f2f2',
    offset: 10,
    endOnTick: true,
    maxPadding: 0,
    title: {
      enabled: false,
    },
    labels: { style: {} },
  },

  plotOptions: {
    column: {
      maxPointWidth: 32,
      stickyTracking: false,
      states: {
        hover: {
          enabled: false,
        },
      },
      centerInCategory: true,
    },
    line: {
      stickyTracking: false,
      findNearestPointBy: 'xy',
    },
    area: {
      stickyTracking: false,
      fillOpacity: 0.15,
    },
    columnrange: {
      states: {
        hover: {
          enabled: false,
        },
      },
    },
    series: {
      states: {
        inactive: {
          opacity: 1,
        },
        hover: {
          enabled: false,
        },
      },
    },
  },
  tooltip: new Tooltip({
    shadow: {
      color: '#000000',
      offsetX: 0,
      offsetY: 1,
      opacity: 0.03,
      width: 6,
    },
  }),
};

export default class FlexibleColumnChartConfig extends DefaultHighchartConfig {
  constructor(timezone) {
    super();
    this.config = copy(config, true);
    this.setTimezone(timezone);
  }

  setXAxisTickInterval(interval) {
    this.config.xAxis.tickInterval = interval;
  }

  setHeight(heightType) {
    let height = heightType === 'custom' ? 280 : 325;
    this.config.chart.height = height;
  }

  setStep(step) {
    this.config.xAxis.labels.step = step;
  }

  setXAxisOverflow(overflow) {
    this.config.xAxis.labels.overflow = overflow;
  }

  setXAxisTickPositioner(tickPositioner) {
    this.config.xAxis.tickPositioner = tickPositioner;
  }

  setXAxisFontSize(size) {
    this.config.xAxis.labels.style.fontSize = size;
  }

  setYAxisTickInterval(interval) {
    this.config.yAxis.tickInterval = interval;
  }

  setMinimumPointLength(px) {
    this.config.plotOptions.series['minPointLength'] = px;
  }

  setStacking(stackOption, chartType) {
    let options = this.config.plotOptions[chartType];
    options.stacking = stackOption;
    options.borderWidth = 0;
    this.config.yAxis.reversedStacks = false;
    if (stackOption === 'percent') {
      this.config.yAxis.ceiling = 100;
      this.config.yAxis.tickInterval = 25;
      this.config.yAxis.labels.format = '{text}%';
      this.config.yAxis.labels.formatter = undefined;
    }
  }

  setColorByPoint(value) {
    this.config.plotOptions.column.colorByPoint = value;
  }

  setColumnHoverState(state) {
    this.config.plotOptions.column.states.hover.enabled = state;
  }

  setSpacingRight(value) {
    this.config.chart.spacingRight = value;
  }

  useDarkTooltips() {
    this.config.tooltip.backgroundColor = PALETTE.black;
    this.config.tooltip.borderColor = PALETTE.black;
    this.config.tooltip.style.color = PALETTE['gray-light'];
    this.config.tooltip.shadow.color = PALETTE['border-shadow-dark'];
    this.config.tooltip.shadow.width = 1;
  }

  disableLabelsAndGridlines() {
    this.config.xAxis.labels = { enabled: false };
    this.config.yAxis.labels = { enabled: false };
    this.config.yAxis.gridLineColor = 'transparent';
  }

  disableLegend() {
    this.config.legend.enabled = false;
  }
}
