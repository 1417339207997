/* RESPONSIBLE TEAM: team-reporting */

// @ts-ignore
import { copy } from 'ember-copy';
// @ts-ignore
import PALETTE from '@intercom/pulse/lib/palette';
// @ts-ignore
import { sanitizeHtml } from '@intercom/pulse/lib/sanitize';
import moment from 'moment-timezone';
import { type VISUALIZATION_TYPES } from 'embercom/models/reporting/custom/visualization-options';
import { trackAnalyticsEventForChart } from 'embercom/lib/reporting/analytics';
import Tooltip from 'embercom/lib/reporting/flexible/tooltip';
import { HEIGHT_TYPES } from 'embercom/models/reporting/custom/chart';

export const DONUT_CHART_INNER_SIZE = '60%';
type VisualizationType = (typeof VISUALIZATION_TYPES)[keyof typeof VISUALIZATION_TYPES];

const config = {
  // Test fiddle setup with config here https://jsfiddle.net/h08fcpmy/
  title: '',
  legend: {
    enabled: true,
    align: 'center',
    margin: 27,
    layout: 'horizontal',
    itemHoverStyle: { color: null, cursor: 'pointer' },
    itemStyle: {
      fontSize: '12px',
      fontWeight: 'normal',
      color: PALETTE.gray,
    },
    labelFormat: '{name}',
    maxHeight: 70,
  },
  chart: {
    spacingLeft: 3,
    // the height is also used to define the min-height of the spinner
    // please reflect any changes in app/styles/structures/reporting/_flexible.scss
    height: 325,
    style: {
      fontFamily:
        // eslint-disable-next-line @intercom/intercom/no-bare-strings
        'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
      fontSize: '12px',
      color: '#78909c',
    },
    spacingBottom: 0,
  },
  tooltip: new Tooltip({
    shadow: {
      color: PALETTE['gray-lightest'],
      offsetX: 0,
      offsetY: 0,
      opacity: 1,
      width: 6,
    },
  }),
  credits: {
    enabled: false,
  },

  plotOptions: {
    pie: {
      cursor: 'pointer',
      showInLegend: true,
      dataLabels: {
        enabled: false,
        style: {
          fontSize: '12px',
          fontWeight: 'normal',
        },
      },
    },
  },
};

export default class FlexibleDonutChartConfig {
  declare config: any;

  // timezone e.g Europe/Oslo
  constructor(timezone: string) {
    this.config = copy(config, true);
    this.setTimezone(timezone);
  }

  setTooltipFormatter(fn: Function) {
    this.config.tooltip.formatter = function () {
      let element = this.point?.graphic?.element;
      if (element) {
        trackAnalyticsEventForChart(element, { action: 'hovered' });
      }
      return sanitizeHtml(fn.call(this)).string;
    };
  }
  setHeight(heightType: string) {
    if (heightType === HEIGHT_TYPES.TALL) {
      this.config.chart.height = 400;
    } else {
      this.config.chart.height = 325;
    }
  }

  setTimezone(timezone: string) {
    this.config.time = { timezone, moment };
  }

  setChartType(type: VisualizationType) {
    this.config.chart.type = type;
  }

  setColors(colors: string[]) {
    this.config.colors = colors;
  }

  useDarkTooltips() {
    this.config.tooltip.backgroundColor = PALETTE.black;
    this.config.tooltip.borderColor = PALETTE.black;
    this.config.tooltip.style.color = PALETTE['gray-light'];
    this.config.tooltip.shadow.color = PALETTE['border-shadow-dark'];
    this.config.tooltip.shadow.width = 1;
  }

  disableLegend() {
    this.config.legend.enabled = false;
  }

  enableDataLabel(formatter: Function) {
    this.config.plotOptions.pie.dataLabels.enabled = true;
    this.config.plotOptions.pie.dataLabels.formatter = function () {
      return sanitizeHtml(formatter.call(this)).string;
    };
  }
}
