/* RESPONSIBLE TEAM: team-ai-chatbot */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
export const LANGUAGES_RELEASED_TO_EVERYBODY = [
  { text: 'Arabic', value: 'ar' },
  { text: 'Bosnian', value: 'bs' },
  { text: 'Portuguese (Brazilian)', value: 'pt-BR' },
  { text: 'Bulgarian', value: 'bg' },
  { text: 'Catalan', value: 'ca' },
  { text: 'Chinese (Simplified)', value: 'zh-CN' },
  { text: 'Chinese (Traditional)', value: 'zh-TW' },
  { text: 'Croatian', value: 'hr' },
  { text: 'Czech', value: 'cs' },
  { text: 'Danish', value: 'da' },
  { text: 'Dutch', value: 'nl' },
  { text: 'English', value: 'en' },
  { text: 'Estonian', value: 'et' },
  { text: 'Finnish', value: 'fi' },
  { text: 'French', value: 'fr' },
  { text: 'German', value: 'de' },
  { text: 'Greek', value: 'el' },
  { text: 'Hebrew', value: 'he' },
  { text: 'Hungarian', value: 'hu' },
  { text: 'Indonesian', value: 'id' },
  { text: 'Italian', value: 'it' },
  { text: 'Japanese', value: 'ja' },
  { text: 'Korean', value: 'ko' },
  { text: 'Latviski/Latvian', value: 'lv' },
  { text: 'Lithuanian', value: 'lt' },
  { text: 'Mongolian', value: 'mn' },
  { text: 'Norwegian', value: 'nb' },
  { text: 'Polish', value: 'pl' },
  { text: 'Portuguese', value: 'pt' },
  { text: 'Romanian', value: 'ro' },
  { text: 'Russian', value: 'ru' },
  { text: 'Serbian', value: 'sr' },
  { text: 'Slovenian', value: 'sl' },
  { text: 'Spanish', value: 'es' },
  { text: 'Swedish', value: 'sv' },
  { text: 'Turkish', value: 'tr' },
  { text: 'Ukrainian', value: 'uk' },
  { text: 'Vietnamese', value: 'vi' },
];
