/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-classic-classes */
import moment from 'moment-timezone';
import platform from 'embercom/lib/browser-platform';
import EmberObject from '@ember/object';

const DIGITS_ONLY_REGEX = /^\d+$/;

const CURRENT_TEAMMATE_PLACEHOLDER_ID = -2;

const CURRENT_TEAMMATE_TRANSLATION_KEY = 'admins.admin.current-teammate';

const REALTIME_EVENT_NAMES = [
  'ThreadAssigned',
  'ThreadCreated',
  'ThreadClosed',
  'ThreadReopened',
  'ThreadUpdated',
  'ThreadSnoozed',
  'ThreadUnsnoozed',
  'UserBlocked',
  'MentionCreated',
  'ThreadIngested',
];

const OFFLINE_NOTIFICATION_KEY = 'networkNotReachable';

const CONVERSATION_PRIORITIES = {
  not_priority: 0,
  priority: 64,
};

const CONVERSATION_STATES = {
  open: 0,
  opened: 0,
  closed: 1,
  snoozed: 2,
};

const CONVERSATION_STATE_LABELS = {
  opened: 'Open',
  closed: 'Closed',
  snoozed: 'Snoozed',
};

const SNOOZED_UNTIL_MAP = [
  EmberObject.extend({
    key: 'later_today',
    intlKey: 'later',
    value: 'Later today',
    eventLabel: 'until later today',
    metaLabel: 'In 3 hours',

    get absoluteUnsnoozeTime() {
      return moment().add(3, 'hours').toISOString();
    },
  }).create(),
  EmberObject.extend({
    key: 'tomorrow',
    intlKey: 'tomorrow',
    value: 'Tomorrow',
    eventLabel: 'until tomorrow',
    shortcut: platform.isMac ? '⌘+Option+Enter' : 'CTRL+Alt+Enter',

    get metaLabel() {
      return `${moment().add(1, 'day').format('ddd')} 9am`;
    },

    get absoluteUnsnoozeTime() {
      return moment().startOf('day').add(1, 'day').add(9, 'hours').toISOString();
    },
  }).create(),
  EmberObject.extend({
    key: 'monday',
    intlKey: 'monday',
    value: 'Monday',
    eventLabel: 'until next Monday',
    metaLabel: 'Mon 9am',

    get absoluteUnsnoozeTime() {
      return moment().startOf('isoWeek').add(1, 'week').add(9, 'hours').toISOString();
    },
  }).create(),
  EmberObject.extend({
    key: 'next_week',
    intlKey: 'one-week',
    value: 'One week',
    eventLabel: 'for one week',

    get metaLabel() {
      return moment().add(1, 'week').format('ddd ha');
    },

    get absoluteUnsnoozeTime() {
      return moment().add(1, 'week').toISOString();
    },
  }).create(),
  EmberObject.extend({
    key: 'next_month',
    intlKey: 'one-month',
    value: 'One month',
    eventLabel: 'for one month',

    get metaLabel() {
      return moment().add(1, 'month').format('MMM D');
    },

    get absoluteUnsnoozeTime() {
      return moment().startOf('day').add(1, 'month').add(9, 'hours').toISOString();
    },
  }).create(),
];

const SIDEBAR_EXPANDED_CONTENT_VISIBILITY = {
  collapsed: 0,
  expanded: 1,
  expandedWithHiddenLabel: 2,
};

const RULE_ACTION_MAP = {
  'assign-conversation': {
    identifier: 'assign-conversation',
    intlKey: 'assign-conversation',
    text: 'Assign to',
    icon: 'assignment',
    isCustomerFacing: false,
    isAssignment: true,
    order: 0,
  },
  'reassign-conversation': {
    identifier: 'reassign-conversation',
    intlKey: 'reassign-conversation',
    text: 'Reassign to',
    icon: 'assignment',
    isCustomerFacing: false,
    isAssignment: true,
    order: 3,
  },
  'assign-conversation-to-owner': {
    identifier: 'assign-conversation-to-owner',
    intlKey: 'assign-conversation-to-owner',
    text: 'Assign to Owner',
    icon: 'assignment',
    isCustomerFacing: false,
    isAssignment: true,
    order: 5,
  },
  'tag-user': {
    identifier: 'tag-user',
    intlKey: 'tag-user',
    text: 'Tag person',
    icon: 'tag',
    isCustomerFacing: false,
    isAssignment: false,
    order: 10,
  },
  'send-to-salesforce': {
    identifier: 'send-to-salesforce',
    intlKey: 'send-to-salesforce',
    text: 'Create lead in Salesforce',
    icon: 'salesforce',
    isCustomerFacing: false,
    isAssignment: false,
    order: 15,
  },
  'create-salesforce-case': {
    identifier: 'create-salesforce-case',
    intlKey: 'create-salesforce-case',
    text: 'Create case in Salesforce',
    icon: 'salesforce',
    isCustomerFacing: false,
    isAssignment: false,
    order: 17,
  },
  'send-to-hubspot': {
    identifier: 'send-to-hubspot',
    intlKey: 'send-to-hubspot',
    text: 'Create contact in HubSpot',
    icon: 'person',
    isCustomerFacing: false,
    isAssignment: false,
    order: 20,
  },
  'send-to-marketo': {
    identifier: 'send-to-marketo',
    intlKey: 'send-to-marketo',
    text: 'Enrich lead in Marketo',
    icon: 'marketo',
    isCustomerFacing: false,
    isAssignment: false,
    order: 25,
  },
  'trigger-marketo-campaign': {
    identifier: 'trigger-marketo-campaign',
    intlKey: 'trigger-marketo-campaign',
    text: 'Trigger a Marketo Campaign',
    icon: 'marketo',
    isCustomerFacing: false,
    isAssignment: false,
    order: 25,
  },
  'send-follow-up-reply': {
    identifier: 'send-follow-up-reply',
    intlKey: 'send-follow-up-reply',
    text: 'Send bot message',
    icon: 'chat-bubble',
    isCustomerFacing: true,
    isAssignment: false,
    order: 30,
  },
  'send-app': {
    identifier: 'send-app',
    intlKey: 'send-app',
    text: 'Send an app',
    icon: 'app-store',
    isCustomerFacing: true,
    isAssignment: false,
    order: 35,
  },
  'send-app-without-introduction': {
    identifier: 'send-app-without-introduction',
    intlKey: 'send-app-without-introduction',
    text: 'Send an app',
    icon: 'app-store',
    isCustomerFacing: true,
    isAssignment: false,
    order: 40,
  },
  'send-app-with-introduction': {
    identifier: 'send-app-with-introduction',
    intlKey: 'send-app-with-introduction',
    text: 'Send bot message and an app',
    icon: 'app-store',
    isCustomerFacing: true,
    isAssignment: false,
    order: 45,
  },
  'snooze-conversation': {
    identifier: 'snooze-conversation',
    intlKey: 'snooze-conversation',
    text: 'Snooze conversation',
    icon: 'clock',
    isCustomerFacing: false,
    isAssignment: false,
    order: 50,
  },
  'close-conversation': {
    identifier: 'close-conversation',
    intlKey: 'close-conversation',
    text: 'Close conversation',
    icon: 'check',
    isCustomerFacing: false,
    isAssignment: false,
    order: 51,
  },
  'disable-end-user-follow-up-replies': {
    identifier: 'disable-end-user-follow-up-replies',
    intlKey: 'disable-end-user-follow-up-replies',
    text: 'Disable the composer',
    icon: 'remove',
    isCustomerFacing: false,
    isAssignment: false,
    order: 55,
  },
  'close-conversation-with': {
    identifier: 'close-conversation-with',
    intlKey: 'close-conversation-with',
    text: 'Send bot message and close conversation',
    icon: 'check',
    isCustomerFacing: true,
    isAssignment: false,
    order: 60,
  },
  'change-conversation-priority': {
    identifier: 'change-conversation-priority',
    intlKey: 'change-conversation-priority',
    text: 'Mark as priority',
    icon: 'star',
    isCustomerFacing: false,
    isAssignment: false,
    order: 65,
  },
  'apply-conversation-sla': {
    identifier: 'apply-conversation-sla',
    intlKey: 'apply-conversation-sla',
    text: 'Apply conversation SLA',
    icon: 'sla',
    isCustomerFacing: false,
    isAssignment: false,
    order: 70,
  },
  'add-tag-to-conversation': {
    identifier: 'add-tag-to-conversation',
    intlKey: 'add-tag-to-conversation',
    text: 'Tag conversation',
    icon: 'tag',
    isCustomerFacing: false,
    isAssignment: false,
    order: 75,
  },
  'workflow-connector-action': {
    identifier: 'workflow-connector-action',
    intlKey: 'workflow-connector-action',
    text: 'Custom action (using API)',
    icon: 'webhook',
    stamp: { text: 'Beta', color: 'sky' },
    isCustomerFacing: false,
    isAssignment: false,
    order: 80,
  },
  'notify-slack-channel': {
    identifier: 'notify-slack-channel',
    intlKey: 'notify-slack-channel',
    text: 'Notify public Slack channel',
    icon: 'slack',
    isCustomerFacing: false,
    isAssignment: false,
    order: 87,
  },
  'notify-private-slack-channel': {
    identifier: 'notify-private-slack-channel',
    intlKey: 'notify-private-slack-channel',
    text: 'Notify private Slack channel',
    icon: 'slack',
    isCustomerFacing: false,
    isAssignment: false,
    order: 89,
  },
  'set-conversation-data-attribute': {
    identifier: 'set-conversation-data-attribute',
    isCustomerFacing: false,
  },
  'set-ticket-state': {
    identifier: 'set-ticket-state',
    intlKey: 'set-ticket-state',
    text: 'Set ticket state',
    icon: 'ticket',
    isCustomerFacing: false,
    isAssignment: false,
    order: 71,
  },
  'set-ticket-custom-state': {
    identifier: 'set-ticket-custom-state',
    intlKey: 'set-ticket-custom-state',
    text: 'Set ticket state',
    icon: 'ticket',
    isCustomerFacing: false,
    isAssignment: false,
    order: 72,
  },
  'add-conversation-topic': {
    identifier: 'add-conversation-topic',
    intlKey: 'add-conversation-topic',
    text: 'Add topic',
    icon: 'insights',
    isCustomerFacing: false,
    isAssignment: false,
    order: 90,
  },
  'remove-tag-from-conversation': {
    identifier: 'remove-tag-from-conversation',
    intlKey: 'remove-tag-from-conversation',
    text: 'Remove conversation tag',
    icon: 'tag',
    isCustomerFacing: false,
    isAssignment: false,
    order: 76,
  },
  'remove-tag-from-user': {
    identifier: 'remove-tag-from-user',
    intlKey: 'remove-tag-from-person',
    text: 'Remove person tag',
    icon: 'tag',
    isCustomerFacing: false,
    isAssignment: false,
    order: 11,
  },
  'set-language-override': {
    identifier: 'set-language-override',
    intlKey: 'set-language-override',
    text: 'Set language override',
    icon: 'language',
    isCustomerFacing: false,
    isAssignment: false,
    order: 91,
  },
  'set-user-data-attribute': {
    identifier: 'set-user-data-attribute',
    isCustomerFacing: false,
  },
};

const DEFAULT_MACRO_TYPES = [
  {
    id: 'opener',
    name: 'Starting conversations',
    intlKey: 'starting-conversations',
  },
  {
    id: 'reply',
    name: 'Replying',
    intlKey: 'replying',
  },
  {
    id: 'note',
    name: 'Adding notes',
    intlKey: 'adding-notes',
  },
];

const createConversationDataAttributeItems = function (items) {
  let baseOrder = 80;
  return items.map((item) => ({
    identifier: 'set-conversation-data-attribute',
    text: `Set ${item.name}`,
    name: item.name,
    attribute_identifier: item.identifier,
    attribute_type: item.type,
    icon: 'transfer',
    isCustomerFacing: false,
    isAssignment: false,
    order: baseOrder++,
  }));
};

const createUserDataAttributeItems = function (items) {
  return items.map((item) => ({
    name: item.name,
    attribute_identifier: item.identifier,
  }));
};

const DEFAULT_TICKET_TYPE_ATTRIBUTES = {
  TITLE: '_default_title_',
  DESCRIPTION: '_default_description_',
};

export {
  DIGITS_ONLY_REGEX,
  CURRENT_TEAMMATE_PLACEHOLDER_ID,
  CURRENT_TEAMMATE_TRANSLATION_KEY,
  CONVERSATION_PRIORITIES,
  CONVERSATION_STATES,
  CONVERSATION_STATE_LABELS,
  OFFLINE_NOTIFICATION_KEY,
  REALTIME_EVENT_NAMES,
  SNOOZED_UNTIL_MAP,
  SIDEBAR_EXPANDED_CONTENT_VISIBILITY,
  RULE_ACTION_MAP,
  DEFAULT_MACRO_TYPES,
  DEFAULT_TICKET_TYPE_ATTRIBUTES,
  createConversationDataAttributeItems,
  createUserDataAttributeItems,
};
