/* RESPONSIBLE TEAM: team-knowledge-interop */

import { EntityType } from 'embercom/models/data/entity-types';
import { State } from 'embercom/models/data/matching-system/matching-constants';

export const AI_CONTENT_ENTITIES: ReadonlyArray<EntityType> = [
  EntityType.ArticleContent,
  EntityType.ExternalContent,
  EntityType.ContentSnippet,
  EntityType.FileSourceContent,
] as const;

export const REVIEWABLE_CONTENT_ENTITIES: ReadonlyArray<EntityType> = [
  EntityType.ContentSnippet,
] as const;

export function aiContentEntities() {
  return AI_CONTENT_ENTITIES;
}

export enum AiContentState {
  USED_BY_FIN = State.Live,
  NOT_USED_BY_FIN = State.Paused,
}

export const AI_CONTENT_ENTITIES_NAMES = {
  [EntityType.ArticleContent]: 'article',
  [EntityType.ExternalContent]: 'external-page',
  [EntityType.ContentSnippet]: 'content-snippet',
  [EntityType.FileSourceContent]: 'file',
} as const;

export enum FinAvailability {
  DERIVED = 0,
  NOT_USED = 1,
  ON = 2,
  OFF = 3,
}

export interface Predicate {
  attribute: string;
  type: string;
  comparison: string;
  value: string;
}

export const LEARN_MORE_CONTENT_IDS = {
  snippet: 7860264,
  pdf: 8124534,
  controlContent: 7860264,
  bestPractices: 7860255,
  tour: 437066,
  contentSuggestions: 8193558,
};

export interface TemplateCardType {
  id: number;
  title: string;
  summary: string;
  icon: string;
  color: string;
}
export const TEXT_CONTENT = {
  id: EntityType.ContentSnippet,
  title: 'text',
  summary: 'text-summary',
  icon: 'note',
  color: 'blue',
};

export const EXTERNAL_CONTENT = {
  id: EntityType.ExternalContent,
  title: 'public-url',
  summary: 'public-url-summary',
  icon: 'link',
  color: 'mint',
};

export const FILE_CONTENT = {
  id: EntityType.FileSourceContent,
  title: 'file',
  summary: 'file-summary',
  icon: 'attachment',
  color: 'orange',
};

export const ARTICLE_CONTENT = {
  id: EntityType.ArticleContent,
  title: 'article',
  summary: 'article-summary',
  icon: 'article',
  color: 'dark-blue',
};

export const CUSTOM_ANSWER_CONTENT = {
  id: EntityType.Answer,
  title: 'custom-answer',
  summary: 'custom-answer-summary',
  icon: 'tooltip',
  color: 'red',
};

export interface Language {
  locale: string;
  name: string;
}

/* eslint-disable @intercom/intercom/no-bare-strings */
export const FIN_SUPPORTED_LANGUAGES: ReadonlyArray<Language> = [
  { locale: 'ar', name: 'Arabic' },
  { locale: 'bn', name: 'Bengali' },
  { locale: 'bs', name: 'Bosnian' },
  { locale: 'pt-BR', name: 'Brazilian Portuguese' },
  { locale: 'bg', name: 'Bulgarian' },
  { locale: 'ca', name: 'Catalan' },
  { locale: 'hr', name: 'Croatian' },
  { locale: 'cs', name: 'Czech' },
  { locale: 'da', name: 'Danish' },
  { locale: 'nl', name: 'Dutch' },
  { locale: 'en', name: 'English' },
  { locale: 'et', name: 'Estonian' },
  { locale: 'fi', name: 'Finnish' },
  { locale: 'fr', name: 'French' },
  { locale: 'de', name: 'German' },
  { locale: 'de-form', name: 'German (Formal)' },
  { locale: 'el', name: 'Greek' },
  { locale: 'he', name: 'Hebrew' },
  { locale: 'hi', name: 'Hindi' },
  { locale: 'hu', name: 'Hungarian' },
  { locale: 'id', name: 'Indonesian' },
  { locale: 'it', name: 'Italian' },
  { locale: 'ja', name: 'Japanese' },
  { locale: 'ko', name: 'Korean' },
  { locale: 'lv', name: 'Latvian' },
  { locale: 'lt', name: 'Lithuanian' },
  { locale: 'ms', name: 'Malay' },
  { locale: 'mn', name: 'Mongolian' },
  { locale: 'nb', name: 'Norwegian' },
  { locale: 'fa-IR', name: 'Persian' },
  { locale: 'pl', name: 'Polish' },
  { locale: 'pt', name: 'Portuguese' },
  { locale: 'ro', name: 'Romanian' },
  { locale: 'ru', name: 'Russian' },
  { locale: 'sr', name: 'Serbian' },
  { locale: 'zh-CN', name: 'Simplified Chinese' },
  { locale: 'sl', name: 'Slovenian' },
  { locale: 'es', name: 'Spanish' },
  { locale: 'sw', name: 'Swahili' },
  { locale: 'sv', name: 'Swedish' },
  { locale: 'th', name: 'Thai' },
  { locale: 'zh-TW', name: 'Traditional Chinese' },
  { locale: 'tr', name: 'Turkish' },
  { locale: 'uk', name: 'Ukrainian' },
  { locale: 'vi', name: 'Vietnamese' },
];
/* eslint-enable @intercom/intercom/no-bare-strings */

export const FIN_DEFAULT_LOCALE = 'en';

export const AI_COLUMN_DATA: Record<
  string,
  {
    valuePath: string;
    type?: string;
    labelIntlKey?: string;
    tooltipIntlKey?: string;
    isDisabled?: boolean;
    isSortable?: boolean;
    minWidth?: string;
  }
> = {
  checkBox: {
    valuePath: 'checkBox',
    type: 'checkbox',
  },
  title: {
    valuePath: 'title',
    labelIntlKey: 'ai-content-library.list.columns.title',
    isDisabled: true,
  },
  state: {
    valuePath: 'state',
    labelIntlKey: 'ai-content-library.list.columns.state',
  },
  locale: {
    valuePath: 'locale',
    labelIntlKey: 'ai-content-library.list.columns.locale',
  },
  contentType: {
    valuePath: 'contentType',
    labelIntlKey: 'ai-content-library.list.columns.content-type',
  },
  segments: {
    valuePath: 'segments',
    labelIntlKey: 'ai-content-library.list.columns.segments',
  },
  uses: {
    valuePath: 'uses',
    labelIntlKey: 'ai-content-library.list.columns.uses',
    tooltipIntlKey: 'ai-content-library.list.tooltips.uses',
  },
  helpCenter: {
    valuePath: 'helpCenter',
    labelIntlKey: 'ai-content-library.list.columns.help-center',
  },
  resolutions: {
    valuePath: 'resolutions',
    labelIntlKey: 'ai-content-library.list.columns.resolutions',
    tooltipIntlKey: 'ai-content-library.list.tooltips.resolutions',
  },
  contentBytesize: {
    valuePath: 'contentBytesize',
    labelIntlKey: 'ai-content-library.list.columns.content-bytesize',
    tooltipIntlKey: 'ai-content-library.list.tooltips.content-bytesize',
    isSortable: true,
  },
  lastUpdatedAt: {
    valuePath: 'lastUpdatedAt',
    labelIntlKey: 'ai-content-library.list.columns.last-updated-at',
    isSortable: true,
  },
  content: {
    valuePath: 'content',
    labelIntlKey: 'ai-content-library.content-reviews.list.columns.content',
    isDisabled: true,
  },
  createdBy: {
    valuePath: 'createdBy',
    labelIntlKey: 'ai-content-library.content-reviews.list.columns.suggested-by',
  },
  createdAt: {
    valuePath: 'createdAt',
    labelIntlKey: 'ai-content-library.content-reviews.list.columns.suggested-at',
    isSortable: true,
  },
};

type AiColumnKey = keyof typeof AI_COLUMN_DATA;

function getAiColumnKeys() {
  let keys: Record<AiColumnKey, string> = {};
  let columnKeys = Object.keys(AI_COLUMN_DATA);
  columnKeys.forEach((key) => {
    keys[key] = AI_COLUMN_DATA[key]['valuePath'];
  });
  return keys;
}

export const AI_COLUMN_KEYS = getAiColumnKeys();

export const DEFAULT_COLUMNS = [
  AI_COLUMN_DATA.checkBox,
  AI_COLUMN_DATA.title,
  AI_COLUMN_DATA.state,
  AI_COLUMN_DATA.locale,
  AI_COLUMN_DATA.contentType,
  AI_COLUMN_DATA.segments,
  AI_COLUMN_DATA.uses,
  AI_COLUMN_DATA.resolutions,
  AI_COLUMN_DATA.lastUpdatedAt,
];

export const CONTENT_REVIEWS_DEFAULT_COLUMNS = [
  AI_COLUMN_DATA.checkBox,
  AI_COLUMN_DATA.title,
  AI_COLUMN_DATA.content,
  AI_COLUMN_DATA.createdBy,
  AI_COLUMN_DATA.createdAt,
];

export interface ColumnData {
  value: string;
  labelIntlKey?: string;
  text?: string;
  isSelected?: boolean;
}

export const CONTENT_REVIEW_SELECTABLE_COLUMNS: ColumnData[] = Object.keys(AI_COLUMN_DATA)
  .filter((key) => {
    return (
      key === AI_COLUMN_KEYS.content ||
      key === AI_COLUMN_KEYS.createdBy ||
      key === AI_COLUMN_KEYS.locale ||
      key === AI_COLUMN_KEYS.createdAt ||
      key === AI_COLUMN_KEYS.lastUpdateAt
    );
  })
  .map((key) => {
    return {
      value: AI_COLUMN_DATA[key]['valuePath'],
      labelIntlKey: AI_COLUMN_DATA[key]['labelIntlKey'],
    };
  });

export const DEFAULT_SELECTABLE_COLUMNS: ColumnData[] = Object.keys(AI_COLUMN_DATA)
  .filter((key) => {
    if (
      key === AI_COLUMN_KEYS.checkBox ||
      key === AI_COLUMN_KEYS.title ||
      key === AI_COLUMN_KEYS.content ||
      key === AI_COLUMN_KEYS.reviewStatus ||
      key === AI_COLUMN_KEYS.createdBy ||
      key === AI_COLUMN_KEYS.createdAt
    ) {
      return false;
    }

    return true;
  })
  .map((key) => {
    return {
      value: AI_COLUMN_DATA[key]['valuePath'],
      labelIntlKey: AI_COLUMN_DATA[key]['labelIntlKey'],
    };
  });
