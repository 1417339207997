/* RESPONSIBLE TEAM: team-frontend-tech */
async function maybeImportCodeMirror() {
  if (!window.CodeMirror) {
    let CodeMirror = (await import('codemirror/lib/codemirror.js')).default;
    window.CodeMirror = CodeMirror;

    await Promise.all([
      import('codemirror/mode/htmlmixed/htmlmixed.js'),
      import('codemirror/mode/xml/xml.js'),
      import('codemirror/mode/css/css.js'),
      import('codemirror/mode/javascript/javascript.js'),
      import('codemirror/addon/lint/lint.js'),
    ]);
  }
}

export { maybeImportCodeMirror };
