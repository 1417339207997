/* RESPONSIBLE TEAM: team-purchase-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */

import {
  ADVANCED_WORKSPACE_MANAGEMENT_ID,
  OPERATOR_PRO_ID,
  PRODUCT_TOURS_ESSENTIAL_ID,
  LEAD_GENERATION_BASE_ID,
  ADVANCED_CUSTOMER_ENGAGEMENT_BASE_ID,
  PROACTIVE_SUPPORT_BASE_ID,
  ADVANCED_WORKSPACE_MANAGEMENT_BASE_ID,
  WHATSAPP_BASE_ID,
  PREMIER_ONBOARDING_BASE_ID,
  PREMIER_SUCCESS_BASE_ID,
  PREMIER_SUPPORT_TIER_1_ID,
  SURVEYS_BASE_ID,
  SWITCH_BASE_ID,
  SMS_BASE_ID,
  ANSWER_BOT_ESSENTIAL_ID,
  SUPPORT_AUTOMATION_BASE_ID,
  FIN_AI_COPILOT_BASE_ID,
} from 'embercom/lib/billing';
import {
  type IntershopAddonCardData,
  type IntershopAddOnData,
  IntershopAddOnMediaAlignment,
} from 'embercom/models/data/intershop/add-on-types';
import { assetUrl } from '@intercom/pulse/helpers/asset-url';

type PlanId = string;

export const ADDON_CARD_DATA: Record<PlanId, IntershopAddonCardData> = {
  [OPERATOR_PRO_ID]: {
    landingPageDescription: 'intershop.addon_card.operator.landing_page_description',
    addonProductPageDescription: 'intershop.addon_card.operator.addon_product_page_description',
    icon: 'intershop/addon-icons/bots',
  },
  [ANSWER_BOT_ESSENTIAL_ID]: {
    landingPageDescription: 'intershop.addon_card.answer_bot.landing_page_description',
    addonProductPageDescription: 'intershop.addon_card.answer_bot.addon_product_page_description',
    icon: 'intershop/addon-icons/bots',
  },
  [FIN_AI_COPILOT_BASE_ID]: {
    landingPageDescription: 'intershop.addon_card.fin_ai_copilot.landing_page_description',
    addonProductPageDescription:
      'intershop.addon_card.fin_ai_copilot.addon_product_page_description',
    addonProductPageSubDescription:
      'intershop.addon_card.fin_ai_copilot.addon_product_page_sub_description',
    icon: 'intershop/addon-icons/fin-ai-copilot',
  },
  [PRODUCT_TOURS_ESSENTIAL_ID]: {
    landingPageDescription: 'intershop.addon_card.product_tours.landing_page_description',
    addonProductPageDescription:
      'intershop.addon_card.product_tours.addon_product_page_description',
    icon: 'intershop/addon-icons/product-tours',
  },
  [LEAD_GENERATION_BASE_ID]: {
    landingPageDescription: 'intershop.addon_card.lead_generation.landing_page_description',
    addonProductPageDescription:
      'intershop.addon_card.lead_generation.addon_product_page_description',
    icon: 'intershop/addon-icons/advanced-lead-generation',
  },
  [SUPPORT_AUTOMATION_BASE_ID]: {
    landingPageDescription: 'intershop.addon_card.support_automation.landing_page_description',
    addonProductPageDescription:
      'intershop.addon_card.support_automation.addon_product_page_description',
    icon: 'intershop/addon-icons/advanced-customer-support',
  },
  [ADVANCED_CUSTOMER_ENGAGEMENT_BASE_ID]: {
    landingPageDescription:
      'intershop.addon_card.advanced_customer_engagement.landing_page_description',
    addonProductPageDescription:
      'intershop.addon_card.advanced_customer_engagement.addon_product_page_description',
    icon: 'intershop/addon-icons/advanced-customer-engagement',
  },
  [PROACTIVE_SUPPORT_BASE_ID]: {
    landingPageDescription: 'intershop.addon_card.proactive_support.landing_page_description',
    addonProductPageDescription:
      'intershop.addon_card.proactive_support.addon_product_page_description',
    icon: 'intershop/addon-icons/support',
  },
  [ADVANCED_WORKSPACE_MANAGEMENT_ID]: {
    landingPageDescription:
      'intershop.addon_card.advanced_workspace_management.landing_page_description',
    addonProductPageDescription:
      'intershop.addon_card.advanced_workspace_management.addon_product_page_description',
    icon: null,
  },
  [ADVANCED_WORKSPACE_MANAGEMENT_BASE_ID]: {
    landingPageDescription: 'intershop.addon_card.custom_api_rate_limit.landing_page_description',
    addonProductPageDescription:
      'intershop.addon_card.custom_api_rate_limit.addon_product_page_description',
    icon: null,
  },
  [WHATSAPP_BASE_ID]: {
    landingPageDescription: 'intershop.addon_card.whatsapp.landing_page_description',
    addonProductPageDescription: 'intershop.addon_card.whatsapp.addon_product_page_description',
    icon: 'intershop/addon-icons/whatsapp',
  },
  [PREMIER_ONBOARDING_BASE_ID]: {
    landingPageDescription: 'intershop.addon_card.premier_onboarding.landing_page_description',
    addonProductPageDescription:
      'intershop.addon_card.premier_onboarding.addon_product_page_description',
    icon: null,
  },
  [PREMIER_SUCCESS_BASE_ID]: {
    landingPageDescription: 'intershop.addon_card.premier_success.landing_page_description',
    addonProductPageDescription:
      'intershop.addon_card.premier_success.addon_product_page_description',
    icon: null,
  },
  [PREMIER_SUPPORT_TIER_1_ID]: {
    landingPageDescription: 'intershop.addon_card.premier_support.landing_page_description',
    addonProductPageDescription:
      'intershop.addon_card.premier_support.addon_product_page_description',
    icon: null,
  },
  [SURVEYS_BASE_ID]: {
    landingPageDescription: 'intershop.addon_card.surveys.landing_page_description',
    addonProductPageDescription: 'intershop.addon_card.surveys.addon_product_page_description',
    icon: 'intershop/addon-icons/surveys',
  },
  [SWITCH_BASE_ID]: {
    landingPageDescription: 'intershop.addon_card.switch.landing_page_description',
    addonProductPageDescription: 'intershop.addon_card.switch.addon_product_page_description',
    icon: 'intershop/addon-icons/switch',
  },
  [SMS_BASE_ID]: {
    landingPageDescription: 'intershop.addon_card.sms.landing_page_description',
    addonProductPageDescription: 'intershop.addon_card.sms.addon_product_page_description',
    icon: 'intershop/addon-icons/switch',
  },
};

const EXAMPLE_SURVEY_ID = '23473226';
const EXAMPLE_PRODUCT_TOUR_ID = '354360';

export const ADD_ON_DATA: Record<PlanId, IntershopAddOnData> = {
  [FIN_AI_COPILOT_BASE_ID]: {
    headline: {
      heading: ['intershop.add_ons.fin_ai_copilot.landing_page.headline.heading.1'],
      description: ['intershop.add_ons.fin_ai_copilot.landing_page.headline.description.1'],
      backgroundColour: 'intershop__addon-page__header-footer',
    },
    horizontal: [
      {
        heading: 'intershop.add_ons.fin_ai_copilot.landing_page.horizontal_sections.1.heading',
        description: {
          text: 'intershop.add_ons.fin_ai_copilot.landing_page.horizontal_sections.1.description.text',
        },
        subHeading:
          'intershop.add_ons.fin_ai_copilot.landing_page.horizontal_sections.1.subHeading',
        subDescription:
          'intershop.add_ons.fin_ai_copilot.landing_page.horizontal_sections.1.subDescription',
        image: {
          url: assetUrl(
            '/assets/images/intershop/product/fin-ai-copilot/training-troubleshooting-guidance.png',
          ),
          side: IntershopAddOnMediaAlignment.RIGHT,
        },
      },
      {
        heading: 'intershop.add_ons.fin_ai_copilot.landing_page.horizontal_sections.2.heading',
        description: {
          text: 'intershop.add_ons.fin_ai_copilot.landing_page.horizontal_sections.2.description.text',
        },
        subHeading:
          'intershop.add_ons.fin_ai_copilot.landing_page.horizontal_sections.2.subHeading',
        subDescription:
          'intershop.add_ons.fin_ai_copilot.landing_page.horizontal_sections.2.subDescription',
        image: {
          url: assetUrl(
            '/assets/images/intershop/product/fin-ai-copilot/best-answers-from-history.png',
          ),
          side: IntershopAddOnMediaAlignment.LEFT,
        },
      },
      {
        heading: 'intershop.add_ons.fin_ai_copilot.landing_page.horizontal_sections.3.heading',
        description: {
          text: 'intershop.add_ons.fin_ai_copilot.landing_page.horizontal_sections.3.description.text',
        },
        subHeading:
          'intershop.add_ons.fin_ai_copilot.landing_page.horizontal_sections.3.subHeading',
        subDescription:
          'intershop.add_ons.fin_ai_copilot.landing_page.horizontal_sections.3.subDescription',
        image: {
          url: assetUrl(
            '/assets/images/intershop/product/fin-ai-copilot/trusted-internal-external-content.png',
          ),
          side: IntershopAddOnMediaAlignment.RIGHT,
        },
      },
      {
        heading: 'intershop.add_ons.fin_ai_copilot.landing_page.horizontal_sections.4.heading',
        description: {
          text: 'intershop.add_ons.fin_ai_copilot.landing_page.horizontal_sections.4.description.text',
        },
        subHeading:
          'intershop.add_ons.fin_ai_copilot.landing_page.horizontal_sections.4.subHeading',
        subDescription:
          'intershop.add_ons.fin_ai_copilot.landing_page.horizontal_sections.4.subDescription',
        image: {
          url: assetUrl(
            '/assets/images/intershop/product/fin-ai-copilot/deep-insights-complete-oversight.png',
          ),
          side: IntershopAddOnMediaAlignment.LEFT,
        },
      },
    ],
    footer: {
      heading: 'intershop.add_ons.fin_ai_copilot.landing_page.footer.heading',
      backgroundColour: 'intershop__addon-page__header-footer',
    },
  },
  [SURVEYS_BASE_ID]: {
    headline: {
      heading: [
        'intershop.add_ons.surveys.landing_page.headline.heading.1',
        'intershop.add_ons.surveys.landing_page.headline.heading.2',
      ],
      description: [],
      backgroundColour: 'intershop__addon-page__header-footer',
    },
    horizontal: [
      {
        heading: 'intershop.add_ons.surveys.landing_page.horizontal_sections.1.heading',
        description: {
          text: 'intershop.add_ons.surveys.landing_page.horizontal_sections.1.description.text',
          list: [
            'intershop.add_ons.surveys.landing_page.horizontal_sections.1.description.list.1',
            'intershop.add_ons.surveys.landing_page.horizontal_sections.1.description.list.2',
            'intershop.add_ons.surveys.landing_page.horizontal_sections.1.description.list.3',
          ],
        },
        image: {
          url: assetUrl('/assets/images/intershop/product/survey/Easy-Setup.webp'),
          side: IntershopAddOnMediaAlignment.RIGHT,
        },
      },
      {
        heading: 'intershop.add_ons.surveys.landing_page.horizontal_sections.2.heading',
        description: {
          text: 'intershop.add_ons.surveys.landing_page.horizontal_sections.2.description.text',
          list: ['intershop.add_ons.surveys.landing_page.horizontal_sections.2.description.list.1'],
        },
        image: {
          url: assetUrl('/assets/images/intershop/product/survey/Surveys-reports.webp'),
          side: IntershopAddOnMediaAlignment.LEFT,
        },
      },
      {
        heading: 'intershop.add_ons.surveys.landing_page.horizontal_sections.3.heading',
        description: {
          text: 'intershop.add_ons.surveys.landing_page.horizontal_sections.3.description.text',
          list: [],
        },
        image: {
          url: assetUrl('/assets/images/intershop/product/survey/Mobile_Surveys.webp'),
          side: IntershopAddOnMediaAlignment.RIGHT,
        },
      },
    ],
    vertical: [
      {
        heading: 'intershop.add_ons.surveys.landing_page.vertical_sections.1.heading',
        description: 'intershop.add_ons.surveys.landing_page.vertical_sections.1.description',
        image: { url: assetUrl('/assets/images/intershop/product/survey/Multi-channel.webp') },
      },
      {
        heading: 'intershop.add_ons.surveys.landing_page.vertical_sections.2.heading',
        description: 'intershop.add_ons.surveys.landing_page.vertical_sections.2.description',
        image: { url: assetUrl('/assets/images/intershop/product/survey/Targeting.webp') },
      },
      {
        heading: 'intershop.add_ons.surveys.landing_page.vertical_sections.3.heading',
        description: 'intershop.add_ons.surveys.landing_page.vertical_sections.3.description',
        image: { url: assetUrl('/assets/images/intershop/product/survey/Workflows.webp') },
      },
    ],
    footer: {
      heading: 'intershop.add_ons.surveys.landing_page.footer.heading',
      description: 'intershop.add_ons.surveys.landing_page.footer.description',
      backgroundColour: 'intershop__addon-page__header-footer',
    },
    example: {
      id: EXAMPLE_SURVEY_ID,
      action: 'startSurvey',
    },
  },
  [ANSWER_BOT_ESSENTIAL_ID]: {
    headline: {
      heading: ['intershop.add_ons.resolution_bot.landing_page.headline.heading.1'],
      description: ['intershop.add_ons.resolution_bot.landing_page.headline.description.1'],
      backgroundColour: 'intershop__addon-page__resolution-bot__header-footer',
    },
    horizontal: [
      {
        heading: 'intershop.add_ons.resolution_bot.landing_page.horizontal_sections.1.heading',
        description: {
          text: 'intershop.add_ons.resolution_bot.landing_page.horizontal_sections.1.description.text',
        },
        video: {
          url: assetUrl('/assets/images/intershop/product/resolution-bot/horizontal-1.webm'),
          side: IntershopAddOnMediaAlignment.RIGHT,
        },
      },
      {
        heading: 'intershop.add_ons.resolution_bot.landing_page.horizontal_sections.2.heading',
        description: {
          text: 'intershop.add_ons.resolution_bot.landing_page.horizontal_sections.2.description.text',
        },
        image: {
          url: assetUrl('/assets/images/intershop/product/resolution-bot/horizontal-2.gif'),
          side: IntershopAddOnMediaAlignment.LEFT,
        },
      },
      {
        heading: 'intershop.add_ons.resolution_bot.landing_page.horizontal_sections.3.heading',
        description: {
          text: 'intershop.add_ons.resolution_bot.landing_page.horizontal_sections.3.description.text',
        },
        video: {
          url: assetUrl('/assets/images/intershop/product/resolution-bot/horizontal-3.webm'),
          side: IntershopAddOnMediaAlignment.RIGHT,
        },
      },
    ],
    vertical: [
      {
        heading: '', // TODO(smg): delete this once PR handling empty states is merged
        description:
          'intershop.add_ons.resolution_bot.landing_page.vertical_sections.1.description',
        image: { url: assetUrl('/assets/images/intershop/product/resolution-bot/vertical-1.png') },
      },
      {
        heading: '', // TODO(smg): delete this once PR handling empty states is merged
        description:
          'intershop.add_ons.resolution_bot.landing_page.vertical_sections.2.description',
        image: { url: assetUrl('/assets/images/intershop/product/resolution-bot/vertical-2.png') },
      },
      {
        heading: '', // TODO(smg): delete this once PR handling empty states is merged
        description:
          'intershop.add_ons.resolution_bot.landing_page.vertical_sections.3.description',
        image: { url: assetUrl('/assets/images/intershop/product/resolution-bot/vertical-3.png') },
      },
    ],
    footer: {
      heading: 'intershop.add_ons.resolution_bot.landing_page.footer.heading',
      backgroundColour: 'intershop__addon-page__resolution-bot__header-footer',
    },
  },
  [WHATSAPP_BASE_ID]: {
    headline: {
      heading: ['intershop.add_ons.whatsapp.landing_page.headline.heading.1'],
      description: ['intershop.add_ons.whatsapp.landing_page.headline.description.1'],
      backgroundColour: 'intershop__addon-page__header-footer',
    },
    horizontal: [
      {
        heading: 'intershop.add_ons.whatsapp.landing_page.horizontal_sections.1.heading',
        description: {
          text: 'intershop.add_ons.whatsapp.landing_page.horizontal_sections.1.description.text',
        },
        image: {
          url: assetUrl('/assets/images/intershop/product/whatsapp/horizontal-1.png'),
          side: IntershopAddOnMediaAlignment.RIGHT,
        },
      },
      {
        heading: 'intershop.add_ons.whatsapp.landing_page.horizontal_sections.2.heading',
        description: {
          text: 'intershop.add_ons.whatsapp.landing_page.horizontal_sections.2.description.text',
        },
        image: {
          url: assetUrl('/assets/images/intershop/product/whatsapp/horizontal-2.png'),
          side: IntershopAddOnMediaAlignment.LEFT,
        },
      },
      {
        heading: 'intershop.add_ons.whatsapp.landing_page.horizontal_sections.3.heading',
        description: {
          text: 'intershop.add_ons.whatsapp.landing_page.horizontal_sections.3.description.text',
        },
        image: {
          url: assetUrl('/assets/images/intershop/product/whatsapp/horizontal-3.png'),
          side: IntershopAddOnMediaAlignment.RIGHT,
        },
      },
      {
        heading: 'intershop.add_ons.whatsapp.landing_page.horizontal_sections.4.heading',
        description: {
          text: 'intershop.add_ons.whatsapp.landing_page.horizontal_sections.4.description.text',
        },
        image: {
          url: assetUrl('/assets/images/intershop/product/whatsapp/horizontal-4.png'),
          side: IntershopAddOnMediaAlignment.LEFT,
        },
      },
    ],
    vertical: [],
    footer: {
      heading: 'intershop.add_ons.whatsapp.landing_page.footer.heading',
      backgroundColour: 'intershop__addon-page__header-footer',
    },
  },
  [PRODUCT_TOURS_ESSENTIAL_ID]: {
    headline: {
      heading: ['intershop.add_ons.product_tours.landing_page.headline.heading.1'],
      backgroundColour: 'intershop__addon-page__header-footer',
    },
    horizontal: [
      {
        heading: 'intershop.add_ons.product_tours.landing_page.horizontal_sections.1.heading',
        description: {
          text: 'intershop.add_ons.product_tours.landing_page.horizontal_sections.1.description.text',
        },
        video: {
          url: assetUrl('/assets/images/intershop/product/product-tours/horizontal-1.webm'),
          side: IntershopAddOnMediaAlignment.LEFT,
        },
      },

      {
        heading: 'intershop.add_ons.product_tours.landing_page.horizontal_sections.3.heading',
        description: {
          text: 'intershop.add_ons.product_tours.landing_page.horizontal_sections.3.description.text',
        },
        image: {
          url: assetUrl('/assets/images/intershop/product/product-tours/horizontal-3.webp'),
          side: IntershopAddOnMediaAlignment.RIGHT,
        },
      },
      {
        heading: 'intershop.add_ons.product_tours.landing_page.horizontal_sections.4.heading',
        description: {
          text: 'intershop.add_ons.product_tours.landing_page.horizontal_sections.4.description.text',
          list: [
            'intershop.add_ons.product_tours.landing_page.horizontal_sections.4.description.list.1',
            'intershop.add_ons.product_tours.landing_page.horizontal_sections.4.description.list.2',
            'intershop.add_ons.product_tours.landing_page.horizontal_sections.4.description.list.3',
            'intershop.add_ons.product_tours.landing_page.horizontal_sections.4.description.list.6',
          ],
        },
        image: {
          url: assetUrl('/assets/images/intershop/product/product-tours/horizontal-4.webp'),
          side: IntershopAddOnMediaAlignment.LEFT,
        },
      },
    ],
    vertical: [
      {
        heading: 'intershop.add_ons.product_tours.landing_page.vertical_sections.1.heading',
        description: 'intershop.add_ons.product_tours.landing_page.vertical_sections.1.description',
        image: { url: assetUrl('/assets/images/intershop/product/product-tours/vertical-1.webp') },
      },
      {
        heading: 'intershop.add_ons.product_tours.landing_page.vertical_sections.2.heading',
        description: 'intershop.add_ons.product_tours.landing_page.vertical_sections.2.description',
        image: { url: assetUrl('/assets/images/intershop/product/product-tours/vertical-2.webp') },
      },
      {
        heading: 'intershop.add_ons.product_tours.landing_page.vertical_sections.3.heading',
        description: 'intershop.add_ons.product_tours.landing_page.vertical_sections.3.description',
        image: { url: assetUrl('/assets/images/intershop/product/product-tours/vertical-3.webp') },
      },
    ],
    footer: {
      heading: 'intershop.add_ons.product_tours.landing_page.footer.heading',
      description: 'intershop.add_ons.product_tours.landing_page.footer.description',
      backgroundColour: 'intershop__addon-page__header-footer',
    },
    example: {
      id: EXAMPLE_PRODUCT_TOUR_ID,
      action: 'startTour',
    },
  },
  [OPERATOR_PRO_ID]: {
    headline: {
      heading: ['intershop.add_ons.custom_bots.landing_page.headline.heading.1'],
      backgroundColour: 'intershop__addon-page__header-footer',
    },
    horizontal: [
      {
        heading: 'intershop.add_ons.custom_bots.landing_page.horizontal_sections.1.heading',
        description: {
          text: 'intershop.add_ons.custom_bots.landing_page.horizontal_sections.1.description.text',
        },
        video: {
          url: assetUrl('/assets/images/intershop/product/custom-bots/horizontal-1.webm'),
          side: IntershopAddOnMediaAlignment.RIGHT,
        },
      },
      {
        heading: 'intershop.add_ons.custom_bots.landing_page.horizontal_sections.2.heading',
        description: {
          text: 'intershop.add_ons.custom_bots.landing_page.horizontal_sections.2.description.text',
        },
        video: {
          url: assetUrl('/assets/images/intershop/product/custom-bots/horizontal-2.mp4'),
          side: IntershopAddOnMediaAlignment.LEFT,
        },
      },
      {
        heading: 'intershop.add_ons.custom_bots.landing_page.horizontal_sections.3.heading',
        description: {
          text: 'intershop.add_ons.custom_bots.landing_page.horizontal_sections.3.description.text',
        },
        image: {
          url: assetUrl('/assets/images/intershop/product/custom-bots/horizontal-3.webp'),
          side: IntershopAddOnMediaAlignment.RIGHT,
        },
      },
    ],
    footer: {
      heading: 'intershop.add_ons.custom_bots.landing_page.footer.heading',
      backgroundColour: 'intershop__addon-page__header-footer',
    },
  },
  [SWITCH_BASE_ID]: {
    headline: {
      heading: ['intershop.add_ons.switch.landing_page.headline.heading.1'],
      backgroundColour: 'intershop__addon-page__header-footer',
    },
    horizontal: [
      {
        heading: 'intershop.add_ons.switch.landing_page.horizontal_sections.1.heading',
        description: {
          text: 'intershop.add_ons.switch.landing_page.horizontal_sections.1.description.text',
        },
        image: {
          url: assetUrl('/assets/images/intershop/product/switch/horizontal-1.gif'),
          side: IntershopAddOnMediaAlignment.RIGHT,
        },
      },
      {
        heading: 'intershop.add_ons.switch.landing_page.horizontal_sections.2.heading',
        description: {
          text: 'intershop.add_ons.switch.landing_page.horizontal_sections.2.description.text',
        },
        image: {
          url: assetUrl('/assets/images/intershop/product/switch/horizontal-2.gif'),
          side: IntershopAddOnMediaAlignment.LEFT,
        },
      },
      {
        heading: 'intershop.add_ons.switch.landing_page.horizontal_sections.3.heading',
        description: {
          text: 'intershop.add_ons.switch.landing_page.horizontal_sections.3.description.text',
        },
        image: {
          url: assetUrl('/assets/images/intershop/product/switch/horizontal-3.gif'),
          side: IntershopAddOnMediaAlignment.RIGHT,
        },
      },
    ],
    vertical: [
      {
        heading: 'intershop.add_ons.switch.landing_page.vertical_sections.1.heading',
        description: 'intershop.add_ons.switch.landing_page.vertical_sections.1.description',
        image: { url: assetUrl('/assets/images/intershop/product/switch/vertical-1.png') },
      },
      {
        heading: 'intershop.add_ons.switch.landing_page.vertical_sections.2.heading',
        description: 'intershop.add_ons.switch.landing_page.vertical_sections.2.description',
        image: { url: assetUrl('/assets/images/intershop/product/switch/vertical-2.png') },
      },
      {
        heading: 'intershop.add_ons.switch.landing_page.vertical_sections.3.heading',
        description: 'intershop.add_ons.switch.landing_page.vertical_sections.3.description',
        image: { url: assetUrl('/assets/images/intershop/product/switch/vertical-3.png') },
      },
    ],
    footer: {
      heading: 'intershop.add_ons.switch.landing_page.footer.heading',
      description: 'intershop.add_ons.switch.landing_page.footer.description',
      backgroundColour: 'intershop__addon-page__header-footer',
    },
  },
  [PROACTIVE_SUPPORT_BASE_ID]: {
    headline: {
      heading: ['intershop.add_ons.proactive_support.landing_page.headline.heading.1'],
      backgroundColour: 'intershop__addon-page__header-footer',
    },
    horizontal: [
      {
        heading: 'intershop.add_ons.proactive_support.landing_page.horizontal_sections.1.heading',
        description: {
          text: 'intershop.add_ons.proactive_support.landing_page.horizontal_sections.1.description.text',
        },
        image: {
          url: assetUrl('/assets/images/intershop/product/proactive-support/horizontal-1.jpg'),
          side: IntershopAddOnMediaAlignment.RIGHT,
        },
      },
      {
        heading: 'intershop.add_ons.proactive_support.landing_page.horizontal_sections.2.heading',
        description: {
          text: 'intershop.add_ons.proactive_support.landing_page.horizontal_sections.2.description.text',
        },
        image: {
          url: assetUrl('/assets/images/intershop/product/proactive-support/horizontal-2.webp'),
          side: IntershopAddOnMediaAlignment.LEFT,
        },
      },
      {
        heading: 'intershop.add_ons.proactive_support.landing_page.horizontal_sections.3.heading',
        description: {
          text: 'intershop.add_ons.proactive_support.landing_page.horizontal_sections.3.description.text',
        },
        image: {
          url: assetUrl('/assets/images/intershop/product/proactive-support/horizontal-3.jpg'),
          side: IntershopAddOnMediaAlignment.RIGHT,
        },
      },
    ],
    footer: {
      heading: 'intershop.add_ons.proactive_support.landing_page.footer.heading',
      backgroundColour: 'intershop__addon-page__header-footer',
    },
  },
  [SMS_BASE_ID]: {
    headline: {
      heading: ['intershop.add_ons.sms.landing_page.headline.heading.1'],
      description: ['intershop.add_ons.sms.landing_page.headline.description.1'],
      backgroundColour: 'intershop__addon-page__header-footer',
    },
    horizontal: [
      {
        heading: 'intershop.add_ons.sms.landing_page.horizontal_sections.1.heading',
        description: {
          text: 'intershop.add_ons.sms.landing_page.horizontal_sections.1.description.text',
        },
        image: {
          url: assetUrl('/assets/images/intershop/product/sms/horizontal-1.png'),
          side: IntershopAddOnMediaAlignment.RIGHT,
        },
      },
      {
        heading: 'intershop.add_ons.sms.landing_page.horizontal_sections.2.heading',
        description: {
          text: 'intershop.add_ons.sms.landing_page.horizontal_sections.2.description.text',
        },
        image: {
          url: assetUrl('/assets/images/intershop/product/sms/horizontal-2.png'),
          side: IntershopAddOnMediaAlignment.LEFT,
        },
      },
      {
        heading: 'intershop.add_ons.sms.landing_page.horizontal_sections.3.heading',
        description: {
          text: 'intershop.add_ons.sms.landing_page.horizontal_sections.3.description.text',
        },
        image: {
          url: assetUrl('/assets/images/intershop/product/sms/horizontal-3.jpg'),
          side: IntershopAddOnMediaAlignment.RIGHT,
        },
      },
    ],
    vertical: [
      {
        heading: 'intershop.add_ons.sms.landing_page.vertical_sections.1.heading',
        description: 'intershop.add_ons.sms.landing_page.vertical_sections.1.description',
        image: { url: assetUrl('/assets/images/intershop/product/sms/vertical-1.jpg') },
      },
      {
        heading: 'intershop.add_ons.sms.landing_page.vertical_sections.2.heading',
        description: 'intershop.add_ons.sms.landing_page.vertical_sections.2.description',
        image: { url: assetUrl('/assets/images/intershop/product/sms/vertical-2.jpg') },
      },
      {
        heading: 'intershop.add_ons.sms.landing_page.vertical_sections.3.heading',
        description: 'intershop.add_ons.sms.landing_page.vertical_sections.3.description',
        image: { url: assetUrl('/assets/images/intershop/product/sms/vertical-3.jpg') },
      },
    ],
    allowedPricingMetricsCharge: [
      'active_phone_numbers_count_us',
      'active_phone_numbers_count_ca',
      'active_phone_numbers_count_uk',
    ],
    customTooltipContent: {
      text: 'intershop.add_ons.sms.custom_tooltip_content.text',
      subtext: 'intershop.add_ons.sms.custom_tooltip_content.subtext',
      link: {
        name: 'intershop.add_ons.sms.custom_tooltip_content.link.name',
        path: 'https://www.intercom.com/help/en/articles/6211963-how-sms-pricing-works',
      },
    },
  },
  [ADVANCED_CUSTOMER_ENGAGEMENT_BASE_ID]: {
    headline: {
      heading: ['intershop.add_ons.advanced_customer_engagement.landing_page.headline.heading.1'],
      backgroundColour: 'intershop__addon-page__header-footer',
    },
    horizontal: [
      {
        heading:
          'intershop.add_ons.advanced_customer_engagement.landing_page.horizontal_sections.1.heading',
        description: {
          text: 'intershop.add_ons.advanced_customer_engagement.landing_page.horizontal_sections.1.description.text',
          list: [
            'intershop.add_ons.advanced_customer_engagement.landing_page.horizontal_sections.1.description.list.1',
            'intershop.add_ons.advanced_customer_engagement.landing_page.horizontal_sections.1.description.list.2',
            'intershop.add_ons.advanced_customer_engagement.landing_page.horizontal_sections.1.description.list.3',
            'intershop.add_ons.advanced_customer_engagement.landing_page.horizontal_sections.1.description.list.4',
            'intershop.add_ons.advanced_customer_engagement.landing_page.horizontal_sections.1.description.list.5',
          ],
        },
        image: {
          url: assetUrl(
            '/assets/images/intershop/product/advanced-customer-engagement/horizontal-1.webp',
          ),
          side: IntershopAddOnMediaAlignment.RIGHT,
        },
      },
      {
        heading:
          'intershop.add_ons.advanced_customer_engagement.landing_page.horizontal_sections.2.heading',
        description: {
          text: 'intershop.add_ons.advanced_customer_engagement.landing_page.horizontal_sections.2.description.text',
          list: [
            'intershop.add_ons.advanced_customer_engagement.landing_page.horizontal_sections.2.description.list.1',
            'intershop.add_ons.advanced_customer_engagement.landing_page.horizontal_sections.2.description.list.2',
            'intershop.add_ons.advanced_customer_engagement.landing_page.horizontal_sections.2.description.list.3',
          ],
        },
        image: {
          url: assetUrl(
            '/assets/images/intershop/product/advanced-customer-engagement/horizontal-2.png',
          ),
          side: IntershopAddOnMediaAlignment.LEFT,
        },
      },
      {
        heading:
          'intershop.add_ons.advanced_customer_engagement.landing_page.horizontal_sections.3.heading',
        description: {
          text: 'intershop.add_ons.advanced_customer_engagement.landing_page.horizontal_sections.3.description.text',
          list: [
            'intershop.add_ons.advanced_customer_engagement.landing_page.horizontal_sections.3.description.list.1',
            'intershop.add_ons.advanced_customer_engagement.landing_page.horizontal_sections.3.description.list.2',
            'intershop.add_ons.advanced_customer_engagement.landing_page.horizontal_sections.3.description.list.3',
          ],
        },
        image: {
          url: assetUrl(
            '/assets/images/intershop/product/advanced-customer-engagement/horizontal-3.jpg',
          ),
          side: IntershopAddOnMediaAlignment.RIGHT,
        },
      },
    ],
    vertical: [
      {
        heading:
          'intershop.add_ons.advanced_customer_engagement.landing_page.vertical_sections.1.heading',
        description:
          'intershop.add_ons.advanced_customer_engagement.landing_page.vertical_sections.1.description',
        image: {
          url: assetUrl(
            '/assets/images/intershop/product/advanced-customer-engagement/vertical-1.png',
          ),
        },
      },
      {
        heading:
          'intershop.add_ons.advanced_customer_engagement.landing_page.vertical_sections.2.heading',
        description:
          'intershop.add_ons.advanced_customer_engagement.landing_page.vertical_sections.2.description',
        image: {
          url: assetUrl(
            '/assets/images/intershop/product/advanced-customer-engagement/vertical-2.png',
          ),
        },
      },
      {
        heading:
          'intershop.add_ons.advanced_customer_engagement.landing_page.vertical_sections.3.heading',
        description:
          'intershop.add_ons.advanced_customer_engagement.landing_page.vertical_sections.3.description',
        image: {
          url: assetUrl(
            '/assets/images/intershop/product/advanced-customer-engagement/vertical-3.png',
          ),
        },
      },
    ],
  },
  [LEAD_GENERATION_BASE_ID]: {
    headline: {
      heading: ['intershop.add_ons.lead_generation.landing_page.headline.heading.1'],
      backgroundColour: 'intershop__addon-page__header-footer',
    },
    horizontal: [
      {
        heading: 'intershop.add_ons.lead_generation.landing_page.horizontal_sections.1.heading',
        description: {
          list: [
            'intershop.add_ons.lead_generation.landing_page.horizontal_sections.1.description.list.1',
            'intershop.add_ons.lead_generation.landing_page.horizontal_sections.1.description.list.2',
            'intershop.add_ons.lead_generation.landing_page.horizontal_sections.1.description.list.3',
          ],
        },
        video: {
          url: assetUrl('/assets/images/intershop/product/lead_generation/horizontal-1.mp4'),
          side: IntershopAddOnMediaAlignment.RIGHT,
        },
      },
    ],
    footer: {
      heading: 'intershop.add_ons.lead_generation.landing_page.footer.heading',
      backgroundColour: 'intershop__addon-page__header-footer',
    },
  },
};
