/* RESPONSIBLE TEAM: team-reporting */
export const CHARTS = [
  {
    visualizationType: 'counter',
    chartSeries: [
      {
        metricId: 'teammate.conversations_closed_per_hour_active',
      },
    ],
  },
  {
    visualizationType: 'counter',
    chartSeries: [
      {
        metricId: 'teammate.conversations_participated_per_hour_active',
      },
    ],
  },
  {
    visualizationType: 'bar',
    chartSeries: [
      {
        metricId: 'teammate.conversations_closed_per_hour_active',
      },
    ],
  },
  {
    visualizationType: 'bar',
    chartSeries: [
      {
        metricId: 'teammate.conversations_participated_per_hour_active',
      },
    ],
  },
  {
    visualizationType: 'bar',
    chartSeries: [
      {
        metricId: 'v2.csat',
        aggregation: 'scripted',
      },
    ],
    viewByTimeInterval: 'month',
  },
  {
    visualizationType: 'line',
    chartSeries: [
      {
        metricId: 'v2.teammate.first_response_time',
        aggregation: 'mean',
      },
    ],
  },
  {
    visualizationType: 'line',
    chartSeries: [
      {
        metricId: 'v2.teammate.subsequent_response_time',
        aggregation: 'mean',
      },
    ],
  },
  {
    visualizationType: 'bar',
    chartSeries: [
      {
        metricId: 'v2.teammate.time_to_close',
        aggregation: 'mean',
      },
    ],
  },
];

export const REPORT = {
  title: 'reporting.report-template-modal.report-templates.teammate-performance-title',
};

export const REPORT_STATE = {
  settings: {},
  filters: {
    type: 'and',
    filters: [
      {
        type: 'category',
        data: {
          attribute: 'teammate',
          property: 'teammate',
          values: ['any'],
        },
      },
    ],
  },
  isStatic: true,
  isTemplate: true,
  dateRangePeriod: 'past_week',
};
