/* RESPONSIBLE TEAM: team-help-desk-ai */

import { type BlockList } from '@intercom/interblocks.ts';
import { EntityType } from 'embercom/models/data/entity-types';
import { type AdminSummaryWireFormat } from 'embercom/objects/inbox/admin-summary';
import { type ArticleContentWireFormat } from 'embercom/objects/inbox/article';
import { type ConversationSummaryWireFormat } from 'embercom/objects/inbox/conversation-summary';
import type Conversation from 'embercom/objects/inbox/conversation';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';
import { type FinSource } from 'embercom/objects/inbox/inbox-assistant-conversation';
import {
  InboxAssistantConversationPart,
  type InboxAssistantConversationPartWireFormat,
} from 'embercom/objects/inbox/inbox-assistant-conversation';
import { objectIcons, objectNames } from 'embercom/models/data/matching-system/matching-constants';
import type Router from '@ember/routing/router-service';
import type Inbox from 'embercom/objects/inbox/inboxes/inbox';

export type ArticleSearchResult = BaseSearchResult & {
  entity_type: EntityType.ArticleContent;
  data: {
    article: NonNullable<
      Pick<
        ArticleContentWireFormat,
        'id' | 'title' | 'last_edited_at' | 'article_id' | 'public_url'
      >
    > & {
      author: Pick<ArticleContentWireFormat['author'], 'id' | 'name'>;
    };
  };
};

export type PastConversationSnippetSearchResult = BaseSearchResult & {
  entity_type: EntityType.PastConversationSnippet;
  data: {
    conversation: Pick<ConversationSummaryWireFormat, 'id' | 'last_updated' | 'team_assignee'> & {
      admin_assignee: Pick<
        NonNullable<ConversationSummaryWireFormat['admin_assignee']>,
        'id' | 'name' | 'image_url' | 'is_operator'
      >;
    };
  };
};

export type ContentSnippetSearchResult = Omit<BaseSearchResult, 'passage'> & {
  entity_type: EntityType.ContentSnippet;
  data: {
    author: Pick<AdminSummaryWireFormat, 'id' | 'name'>;
    updated_at: string;
  };
  passage: BlockList | string;
};

export interface CopilotBaseAnalyticsData {
  conversation_id?: Conversation['id'];
  last_part_entity_type?: RenderablePart['entityType'];
  last_part_entity_id?: RenderablePart['entityId'];
  last_teammate_part_entity_id?: RenderablePart['entityId'];
  last_teammate_part_entity_type?: RenderablePart['entityType'];
  last_end_user_part_entity_id?: RenderablePart['entityId'];
  last_end_user_part_entity_type?: RenderablePart['entityType'];
}

export interface CopilotChatMessageAnalyticsData extends CopilotBaseAnalyticsData {
  section: string;
  ask_fin_version: 'v1' | 'v2' | 'v3' | null;
  ask_fin_experiment_enabled: boolean;
  copilot_efficiency_experiment: boolean;
  copilot_efficiency_experiment_variant?: string;
  inbox_assistant_conversation_id?: number;
  answer_bot_transaction_id?: string;
  search_uuid?: string;
  search_session_id?: string;
}

export enum AnswerStreamingStatus {
  SENDING = 'sending',
  STREAMING_RELATED_CONTENT = 'streaming_related_content',
  STREAMING_ANSWER = 'streaming_answer',
  FINISHED = 'finished',
}

export type BaseSearchResult = {
  entity_type: EntityType;
  entity_id: number;
  score: number;
  title: string;
  passage: string;
  passage_idx: number;
};

export type CopilotInteractionMetadata = Partial<{
  openInSidebar: boolean;
  isFromRelatedContentSheet: boolean;
  isInlineSource: boolean;
  highlightedBlockIndices: [number, number];
  articleVersionId: string;
}>;

export interface NormalizedFinSource {
  data: {
    id?: string | number;
    title?: string;
    body: BlockList;
    updated_at: string;
    icon: InterfaceIconName;
    source_url?: string;
    author: {
      id?: string | number;
      name?: string;
      imageUrl?: string;
    };
  };
  preview?: {
    blocks?: BlockList[];
    passage_ids?: string[];
    blocks_indices?: [number, number][];
    version_id?: string;
  };
  onClickLink: (metadata: CopilotInteractionMetadata, event: PointerEvent) => void;
  entity_id: number;
  entity_type: EntityType;
  instrumentCopy(metadata: CopilotInteractionMetadata): void;
  instrumentHover(metadata: CopilotInteractionMetadata): void;
  instrumentClick(metadata: CopilotInteractionMetadata): void;
}

export enum CopilotSourceSection {
  SourcePreview = 'source-preview',
  List = 'list',
  InlinePreview = 'inline-preview',
}

export enum CopilotLoadingError {
  MissingAllConversationAccess = 'missing-all-conversation-access',
}

export enum CopilotSuggestionLocation {
  EmptyState = 'empty-state',
  InternalCarousel = 'carousel',
  ExternalCarousel = 'external-carousel',
}

export type CopilotSourceTypes =
  | EntityType.ArticleContent
  | EntityType.ContentSnippet
  | EntityType.ConversationExcerpt
  | EntityType.InternalArticle
  | EntityType.FileSourceContent
  | EntityType.ExternalContent
  | EntityType.SavedReply;

export type DeprecatedCopilotSourceTypes = EntityType.SlackThread;

export type AllCopilotSourceTypes = CopilotSourceTypes | DeprecatedCopilotSourceTypes;

export type CopilotPromptResponsePairWireFormat = {
  conversation_id: number;
  parts: InboxAssistantConversationPartWireFormat[];
};

export class CopilotPromptResponsePair {
  conversationId?: number;
  parts: InboxAssistantConversationPart[];

  constructor(conversationId: number | undefined, parts: InboxAssistantConversationPart[]) {
    this.conversationId = conversationId;
    this.parts = parts;
  }

  static deserialize(json: CopilotPromptResponsePairWireFormat): CopilotPromptResponsePair {
    return new CopilotPromptResponsePair(
      json.conversation_id,
      json.parts.map((part) => InboxAssistantConversationPart.deserialize(part)),
    );
  }
}

export function normalizeFinSource(
  source: FinSource,
  workspaceId: string,
  router: Router,
  inbox?: Inbox,
): Partial<NormalizedFinSource> {
  let generateKnowledgeHubUrl = () => {
    return router.urlFor(
      'apps.app.knowledge-hub.view',
      workspaceId,
      objectNames[source.entity_type],
      source.entity_id,
    );
  };

  switch (source.entity_type) {
    case EntityType.ArticleContent:
      return {
        ...source,
        data: {
          author: {
            id: source.data.author?.id,
            name: source.data.author?.name,
          },
          body:
            source.preview?.blocks && source.preview.blocks.length > 0
              ? source.preview?.blocks.flatMap((blockList) => blockList)
              : source.data.article_preview,
          icon: 'article' as const,
          id: source.data.id,
          source_url: source.data.public_url,
          title: source.data.title,
          updated_at: source.data.last_edited_at,
        },
      };
    case EntityType.PastConversationSnippet:
      return {
        ...source,
        data: {
          author: {
            id: source.data.admin_assignee?.id,
            name: source.data.admin_assignee?.name,
            imageUrl: source.data.admin_assignee?.image_url ?? undefined,
          },
          body: [{ type: 'paragraph', text: source.data.passage }],
          icon: 'conversation' as const,
          id: source.data.id,
          source_url: router.urlFor(
            'inbox.workspace.inbox.inbox.conversation.conversation',
            workspaceId,
            inbox?.category || 'shared',
            inbox?.id || 'all',
            source.data.id,
          ),
          title: source.data.title,
          updated_at: source.data.last_updated,
        },
      };
    case EntityType.ContentSnippet:
      return {
        ...source,
        data: {
          author: {
            id: source.data.author?.id,
            name: source.data.author?.name,
          },
          body: source.data.content,
          icon: 'note' as const,
          id: undefined,
          title: source.data.title,
          updated_at: source.data.updated_at,
        },
      };
    case EntityType.InternalArticle:
      return {
        ...source,
        data: {
          author: {
            id: source.data.author_summary?.id,
            imageUrl: source.data.author_summary?.image_url ?? undefined,
            name: source.data.author_summary?.name,
          },
          body:
            source.preview?.blocks && source.preview.blocks.length > 0
              ? source.preview?.blocks.flatMap((blockList) => blockList)
              : source.data.article_preview,
          icon: 'locked' as const,
          id: source.data.id,
          source_url: generateKnowledgeHubUrl(),
          title: source.data.title,
          updated_at: source.data.updated_at,
        },
      };
    case EntityType.ConversationExcerpt:
      return {
        ...source,
        data: {
          author: {
            id: source.data.admin_assignee?.id,
            imageUrl: source.data.admin_assignee?.image_url ?? undefined,
            name: source.data.admin_assignee?.name,
          },
          body: source.data.source_preview,
          icon: 'conversation' as const,
          id: source.data.id,
          source_url: router.urlFor(
            'inbox.workspace.inbox.inbox.conversation.conversation',
            workspaceId,
            inbox?.category || 'shared',
            inbox?.id || 'all',
            source.data.id,
          ),
          title: source.data.title,
          updated_at: source.data.last_updated,
        },
      };
    case EntityType.SlackThread:
      return {
        ...source,
        data: {
          author: {
            id: undefined,
            imageUrl: undefined,
            name: undefined,
          },
          body: source.data.source_preview,
          icon: 'slack' as const,
          id: source.data.id,
          source_url: source.data.source_url,
          title: source.data.title,
          updated_at: source.data.updated_at,
        },
      };
    case EntityType.FileSourceContent:
      return {
        ...source,
        data: {
          author: {
            id: source.data.author_summary?.id,
            imageUrl: source.data.author_summary?.image_url ?? undefined,
            name: source.data.author_summary?.name,
          },
          body: source.data.source_preview,
          icon: objectIcons[EntityType.FileSourceContent],
          id: source.data.id,
          source_url: generateKnowledgeHubUrl(),
          title: source.data.title,
          updated_at: source.data.updated_at,
        },
      };
    case EntityType.ExternalContent:
      return {
        ...source,
        data: {
          author: {
            id: source.data.author_summary?.id,
            imageUrl: source.data.author_summary?.image_url ?? undefined,
            name: source.data.author_summary?.name,
          },
          body: source.data.source_preview,
          icon: objectIcons[EntityType.ExternalContent],
          id: source.data.id,
          source_url: source.data.source_url,
          title: source.data.title,
          updated_at: source.data.updated_at,
        },
      };
    case EntityType.SavedReply:
      return {
        ...source,
        data: {
          id: source.data.id,
          title: source.data.title,
          icon: 'saved-reply',
          body:
            source.preview?.blocks && source.preview.blocks.length > 0
              ? source.preview?.blocks.flatMap((blockList) => blockList)
              : source.data.content,
          updated_at: source.data.updated_at,
          source_url: router.urlFor('apps.app.settings.helpdesk.macros', workspaceId),
          author: {
            id: source.data.author.id,
            name: source.data.author.name,
            imageUrl: source.data.author.image_url ?? undefined,
          },
        },
      };
    default:
      return source as unknown as NormalizedFinSource;
  }
}
