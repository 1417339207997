/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { assetUrl } from '@intercom/pulse/helpers/asset-url';

const countryFlagAssetUrl = (countryCode) => assetUrl(`/assets/svgs/flags/${countryCode}.svg`);

export default {
  af: {
    name: 'Afghanistan', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.afghanistan',
    dial_code: '+93',
    code: 'af',
    asset_url: countryFlagAssetUrl('af'),
  },
  al: {
    name: 'Albania', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.albania',
    dial_code: '+355',
    code: 'al',
    asset_url: countryFlagAssetUrl('al'),
  },
  dz: {
    name: 'Algeria', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.algeria',
    dial_code: '+213',
    code: 'dz',
    asset_url: countryFlagAssetUrl('dz'),
  },
  as: {
    name: 'American Samoa', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.american_samoa',
    dial_code: '+1684',
    code: 'as',
    asset_url: countryFlagAssetUrl('as'),
  },
  ad: {
    name: 'Andorra', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.andorra',
    dial_code: '+376',
    code: 'ad',
    asset_url: countryFlagAssetUrl('ad'),
  },
  ao: {
    name: 'Angola', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.angola',
    dial_code: '+244',
    code: 'ao',
    asset_url: countryFlagAssetUrl('ao'),
  },
  ai: {
    name: 'Anguilla', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.anguilla',
    dial_code: '+1264',
    code: 'ai',
    asset_url: countryFlagAssetUrl('ai'),
  },
  ag: {
    name: 'Antigua and Barbuda', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.antigua_and_barbuda',
    dial_code: '+1268',
    code: 'ag',
    asset_url: countryFlagAssetUrl('ag'),
  },
  ar: {
    name: 'Argentina', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.argentina',
    dial_code: '+54',
    code: 'ar',
    asset_url: countryFlagAssetUrl('ar'),
  },
  am: {
    name: 'Armenia', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.armenia',
    dial_code: '+374',
    code: 'am',
    asset_url: countryFlagAssetUrl('am'),
  },
  aw: {
    name: 'Aruba', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.aruba',
    dial_code: '+297',
    code: 'aw',
    asset_url: countryFlagAssetUrl('aw'),
  },
  au: {
    name: 'Australia', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.australia',
    dial_code: '+61',
    code: 'au',
    asset_url: countryFlagAssetUrl('au'),
  },
  at: {
    name: 'Austria', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.austria',
    dial_code: '+43',
    code: 'at',
    asset_url: countryFlagAssetUrl('at'),
  },
  az: {
    name: 'Azerbaijan', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.azerbaijan',
    dial_code: '+994',
    code: 'az',
    asset_url: countryFlagAssetUrl('az'),
  },
  bs: {
    name: 'Bahamas', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.bahamas',
    dial_code: '+1242',
    code: 'bs',
    asset_url: countryFlagAssetUrl('bs'),
  },
  bh: {
    name: 'Bahrain', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.bahrain',
    dial_code: '+973',
    code: 'bh',
    asset_url: countryFlagAssetUrl('bh'),
  },
  bb: {
    name: 'Barbados', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.barbados',
    dial_code: '+1246',
    code: 'bb',
    asset_url: countryFlagAssetUrl('bb'),
  },
  by: {
    name: 'Belarus', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.belarus',
    dial_code: '+375',
    code: 'by',
    asset_url: countryFlagAssetUrl('by'),
  },
  be: {
    name: 'Belgium', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.belgium',
    dial_code: '+32',
    code: 'be',
    asset_url: countryFlagAssetUrl('be'),
  },
  bz: {
    name: 'Belize', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.belize',
    dial_code: '+501',
    code: 'bz',
    asset_url: countryFlagAssetUrl('bz'),
  },
  bd: {
    name: 'Bangladesh', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.bangladesh',
    dial_code: '+880',
    code: 'bd',
    asset_url: countryFlagAssetUrl('bd'),
  },
  bj: {
    name: 'Benin', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.benin',
    dial_code: '+229',
    code: 'bj',
    asset_url: countryFlagAssetUrl('bj'),
  },
  bm: {
    name: 'Bermuda', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.bermuda',
    dial_code: '+1441',
    code: 'bm',
    asset_url: countryFlagAssetUrl('bm'),
  },
  bt: {
    name: 'Bhutan', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.bhutan',
    dial_code: '+975',
    code: 'bt',
    asset_url: countryFlagAssetUrl('bt'),
  },
  bo: {
    name: 'Bolivia, Plurinational State of bolivia', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.bolivia_plurinational_state_of_bolivia',
    dial_code: '+591',
    code: 'bo',
    asset_url: countryFlagAssetUrl('bo'),
  },
  ba: {
    name: 'Bosnia and Herzegovina', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.bosnia_and_herzegovina',
    dial_code: '+387',
    code: 'ba',
    asset_url: countryFlagAssetUrl('ba'),
  },
  bw: {
    name: 'Botswana', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.botswana',
    dial_code: '+267',
    code: 'bw',
    asset_url: countryFlagAssetUrl('bw'),
  },
  br: {
    name: 'Brazil', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.brazil',
    dial_code: '+55',
    code: 'br',
    asset_url: countryFlagAssetUrl('br'),
  },
  bn: {
    name: 'Brunei Darussalam', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.brunei_darussalam',
    dial_code: '+673',
    code: 'bn',
    asset_url: countryFlagAssetUrl('bn'),
  },
  bg: {
    name: 'Bulgaria', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.bulgaria',
    dial_code: '+359',
    code: 'bg',
    asset_url: countryFlagAssetUrl('bg'),
  },
  bf: {
    name: 'Burkina Faso', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.burkina_faso',
    dial_code: '+226',
    code: 'bf',
    asset_url: countryFlagAssetUrl('bf'),
  },
  bi: {
    name: 'Burundi', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.burundi',
    dial_code: '+257',
    code: 'bi',
    asset_url: countryFlagAssetUrl('bi'),
  },
  kh: {
    name: 'Cambodia', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.cambodia',
    dial_code: '+855',
    code: 'kh',
    asset_url: countryFlagAssetUrl('kh'),
  },
  cm: {
    name: 'Cameroon', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.cameroon',
    dial_code: '+237',
    code: 'cm',
    asset_url: countryFlagAssetUrl('cm'),
  },
  ca: {
    name: 'Canada', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.canada',
    dial_code: '+1',
    code: 'ca',
    asset_url: countryFlagAssetUrl('ca'),
  },
  cv: {
    name: 'Cape Verde', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.cape_verde',
    dial_code: '+238',
    code: 'cv',
    asset_url: countryFlagAssetUrl('cv'),
  },
  ky: {
    name: 'Cayman Islands', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.cayman_islands',
    dial_code: '+ 345',
    code: 'ky',
    asset_url: countryFlagAssetUrl('ky'),
  },
  cf: {
    name: 'Central African Republic', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.central_african_republic',
    dial_code: '+236',
    code: 'cf',
    asset_url: countryFlagAssetUrl('cf'),
  },
  td: {
    name: 'Chad', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.chad',
    dial_code: '+235',
    code: 'td',
    asset_url: countryFlagAssetUrl('td'),
  },
  cl: {
    name: 'Chile', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.chile',
    dial_code: '+56',
    code: 'cl',
    asset_url: countryFlagAssetUrl('cl'),
  },
  cn: {
    name: 'China', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.china',
    dial_code: '+86',
    code: 'cn',
    asset_url: countryFlagAssetUrl('cn'),
  },
  cx: {
    name: 'Christmas Island', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.christmas_island',
    dial_code: '+61',
    code: 'cx',
    asset_url: countryFlagAssetUrl('cx'),
  },
  cc: {
    name: 'Cocos (Keeling) Islands', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.cocos_keeling_islands',
    dial_code: '+61',
    code: 'cc',
    asset_url: countryFlagAssetUrl('cc'),
  },
  co: {
    name: 'Colombia', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.colombia',
    dial_code: '+57',
    code: 'co',
    asset_url: countryFlagAssetUrl('co'),
  },
  km: {
    name: 'Comoros', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.comoros',
    dial_code: '+269',
    code: 'km',
    asset_url: countryFlagAssetUrl('km'),
  },
  cg: {
    name: 'Congo', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.congo',
    dial_code: '+242',
    code: 'cg',
    asset_url: countryFlagAssetUrl('cg'),
  },
  cd: {
    name: 'Congo, The Democratic Republic of the Congo', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.congo_democratic_republic_of_the_congo',
    dial_code: '+243',
    code: 'cd',
    asset_url: countryFlagAssetUrl('cd'),
  },
  ck: {
    name: 'Cook Islands', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.cook_islands',
    dial_code: '+682',
    code: 'ck',
    asset_url: countryFlagAssetUrl('ck'),
  },
  cr: {
    name: 'Costa Rica', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.costa_rica',
    dial_code: '+506',
    code: 'cr',
    asset_url: countryFlagAssetUrl('cr'),
  },
  ci: {
    name: "Cote d'Ivoire",
    nameTranslationKey: 'app.lib.phone-codes.cote_d_ivoire',
    dial_code: '+225',
    code: 'ci',
    asset_url: countryFlagAssetUrl('ci'),
  },
  hr: {
    name: 'Croatia', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.croatia',
    dial_code: '+385',
    code: 'hr',
    asset_url: countryFlagAssetUrl('hr'),
  },
  cy: {
    name: 'Cyprus', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.cyprus',
    dial_code: '+357',
    code: 'cy',
    asset_url: countryFlagAssetUrl('cy'),
  },
  cz: {
    name: 'Czech Republic', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.czech_republic',
    dial_code: '+420',
    code: 'cz',
    asset_url: countryFlagAssetUrl('cz'),
  },
  dk: {
    name: 'Denmark', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.denmark',
    dial_code: '+45',
    code: 'dk',
    asset_url: countryFlagAssetUrl('dk'),
  },
  dj: {
    name: 'Djibouti', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.djibouti',
    dial_code: '+253',
    code: 'dj',
    asset_url: countryFlagAssetUrl('dj'),
  },
  dm: {
    name: 'Dominica', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.dominica',
    dial_code: '+1767',
    code: 'dm',
    asset_url: countryFlagAssetUrl('dm'),
  },
  do: {
    name: 'Dominican Republic', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.dominican_republic',
    dial_code: '+1849',
    code: 'do',
    asset_url: countryFlagAssetUrl('do'),
  },
  ec: {
    name: 'Ecuador', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.ecuador',
    dial_code: '+593',
    code: 'ec',
    asset_url: countryFlagAssetUrl('ec'),
  },
  eg: {
    name: 'Egypt', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.egypt',
    dial_code: '+20',
    code: 'eg',
    asset_url: countryFlagAssetUrl('eg'),
  },
  sv: {
    name: 'El Salvador', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.el_salvador',
    dial_code: '+503',
    code: 'sv',
    asset_url: countryFlagAssetUrl('sv'),
  },
  gq: {
    name: 'Equatorial Guinea', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.equatorial_guinea',
    dial_code: '+240',
    code: 'gq',
    asset_url: countryFlagAssetUrl('gq'),
  },
  er: {
    name: 'Eritrea', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.eritrea',
    dial_code: '+291',
    code: 'er',
    asset_url: countryFlagAssetUrl('er'),
  },
  ee: {
    name: 'Estonia', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.estonia',
    dial_code: '+372',
    code: 'ee',
    asset_url: countryFlagAssetUrl('ee'),
  },
  et: {
    name: 'Ethiopia', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.ethiopia',
    dial_code: '+251',
    code: 'et',
    asset_url: countryFlagAssetUrl('et'),
  },
  fk: {
    name: 'Falkland Islands (Malvinas)', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.falkland_islands_malvinas',
    dial_code: '+500',
    code: 'fk',
    asset_url: countryFlagAssetUrl('fk'),
  },
  fo: {
    name: 'Faroe Islands', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.faroe_islands',
    dial_code: '+298',
    code: 'fo',
    asset_url: countryFlagAssetUrl('fo'),
  },
  fj: {
    name: 'Fiji', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.fiji',
    dial_code: '+679',
    code: 'fj',
    asset_url: countryFlagAssetUrl('fj'),
  },
  fi: {
    name: 'Finland', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.finland',
    dial_code: '+358',
    code: 'fi',
    asset_url: countryFlagAssetUrl('fi'),
  },
  fr: {
    name: 'France', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.france',
    dial_code: '+33',
    code: 'fr',
    asset_url: countryFlagAssetUrl('fr'),
  },
  gf: {
    name: 'French Guiana', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.french_guiana',
    dial_code: '+594',
    code: 'gf',
    asset_url: countryFlagAssetUrl('gf'),
  },
  pf: {
    name: 'French Polynesia', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.french_polynesia',
    dial_code: '+689',
    code: 'pf',
    asset_url: countryFlagAssetUrl('pf'),
  },
  ga: {
    name: 'Gabon', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.gabon',
    dial_code: '+241',
    code: 'ga',
    asset_url: countryFlagAssetUrl('ga'),
  },
  gm: {
    name: 'Gambia', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.gambia',
    dial_code: '+220',
    code: 'gm',
    asset_url: countryFlagAssetUrl('gm'),
  },
  ge: {
    name: 'Georgia', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.georgia',
    dial_code: '+995',
    code: 'ge',
    asset_url: countryFlagAssetUrl('ge'),
  },
  de: {
    name: 'Germany', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.germany',
    dial_code: '+49',
    code: 'de',
    asset_url: countryFlagAssetUrl('de'),
  },
  gh: {
    name: 'Ghana', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.ghana',
    dial_code: '+233',
    code: 'gh',
    asset_url: countryFlagAssetUrl('gh'),
  },
  gi: {
    name: 'Gibraltar', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.gibraltar',
    dial_code: '+350',
    code: 'gi',
    asset_url: countryFlagAssetUrl('gi'),
  },
  gr: {
    name: 'Greece', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.greece',
    dial_code: '+30',
    code: 'gr',
    asset_url: countryFlagAssetUrl('gr'),
  },
  gl: {
    name: 'Greenland', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.greenland',
    dial_code: '+299',
    code: 'gl',
    asset_url: countryFlagAssetUrl('gl'),
  },
  gd: {
    name: 'Grenada', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.grenada',
    dial_code: '+1473',
    code: 'gd',
    asset_url: countryFlagAssetUrl('gd'),
  },
  gg: {
    name: 'Guernsey', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.guernsey',
    dial_code: '+44',
    code: 'gg',
    asset_url: countryFlagAssetUrl('gg'),
  },
  gu: {
    name: 'Guam', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.guam',
    dial_code: '+1671',
    code: 'gu',
    asset_url: countryFlagAssetUrl('gu'),
  },
  gt: {
    name: 'Guatemala', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.guatemala',
    dial_code: '+502',
    code: 'gt',
    asset_url: countryFlagAssetUrl('gt'),
  },
  gn: {
    name: 'Guinea', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.guinea',
    dial_code: '+224',
    code: 'gn',
    asset_url: countryFlagAssetUrl('gn'),
  },
  gw: {
    name: 'Guinea-Bissau', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.guinea_bissau',
    dial_code: '+245',
    code: 'gw',
    asset_url: countryFlagAssetUrl('gw'),
  },
  gy: {
    name: 'Guyana', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.guyana',
    dial_code: '+592',
    code: 'gy',
    asset_url: countryFlagAssetUrl('gy'),
  },
  ht: {
    name: 'Haiti', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.haiti',
    dial_code: '+509',
    code: 'ht',
    asset_url: countryFlagAssetUrl('ht'),
  },
  va: {
    name: 'Holy See (Vatican City State)', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.holy_see_vatican_city_state)',
    dial_code: '+379',
    code: 'va',
    asset_url: countryFlagAssetUrl('va'),
  },
  hn: {
    name: 'Honduras', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.honduras',
    dial_code: '+504',
    code: 'hn',
    asset_url: countryFlagAssetUrl('hn'),
  },
  hk: {
    name: 'Hong Kong', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.hong_kong',
    dial_code: '+852',
    code: 'hk',
    asset_url: countryFlagAssetUrl('hk'),
  },
  hu: {
    name: 'Hungary', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.hungary',
    dial_code: '+36',
    code: 'hu',
    asset_url: countryFlagAssetUrl('hu'),
  },
  is: {
    name: 'Iceland', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.iceland',
    dial_code: '+354',
    code: 'is',
    asset_url: countryFlagAssetUrl('is'),
  },
  in: {
    name: 'India', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.india',
    dial_code: '+91',
    code: 'in',
    asset_url: countryFlagAssetUrl('in'),
  },
  id: {
    name: 'Indonesia', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.indonesia',
    dial_code: '+62',
    code: 'id',
    asset_url: countryFlagAssetUrl('id'),
  },
  iq: {
    name: 'Iraq', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.iraq',
    dial_code: '+964',
    code: 'iq',
    asset_url: countryFlagAssetUrl('iq'),
  },
  ie: {
    name: 'Ireland', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.ireland',
    dial_code: '+353',
    code: 'ie',
    asset_url: countryFlagAssetUrl('ie'),
  },
  im: {
    name: 'Isle of Man', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.isle of Man',
    dial_code: '+44',
    code: 'im',
    asset_url: countryFlagAssetUrl('im'),
  },
  il: {
    name: 'Israel', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.israel',
    dial_code: '+972',
    code: 'il',
    asset_url: countryFlagAssetUrl('il'),
  },
  it: {
    name: 'Italy', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.italy',
    dial_code: '+39',
    code: 'it',
    asset_url: countryFlagAssetUrl('it'),
  },
  jm: {
    name: 'Jamaica', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.jamaica',
    dial_code: '+1876',
    code: 'jm',
    asset_url: countryFlagAssetUrl('jm'),
  },
  jp: {
    name: 'Japan', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.japan',
    dial_code: '+81',
    code: 'jp',
    asset_url: countryFlagAssetUrl('jp'),
  },
  je: {
    name: 'Jersey', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.jersey',
    dial_code: '+44',
    code: 'je',
    asset_url: countryFlagAssetUrl('je'),
  },
  jo: {
    name: 'Jordan', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.jordan',
    dial_code: '+962',
    code: 'jo',
    asset_url: countryFlagAssetUrl('jo'),
  },
  kz: {
    name: 'Kazakhstan', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.kazakhstan',
    dial_code: '+7',
    code: 'kz',
    asset_url: countryFlagAssetUrl('kz'),
  },
  ke: {
    name: 'Kenya', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.kenya',
    dial_code: '+254',
    code: 'ke',
    asset_url: countryFlagAssetUrl('ke'),
  },
  ki: {
    name: 'Kiribati', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.kiribati',
    dial_code: '+686',
    code: 'ki',
    asset_url: countryFlagAssetUrl('ki'),
  },
  ko: {
    name: 'Kosovo', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.kosovo',
    dial_code: '+383',
    code: 'xk',
    asset_url: countryFlagAssetUrl('xk'),
  },
  kr: {
    name: 'Korea, Republic of South Korea', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.korea_republic_of_south_korea',
    dial_code: '+82',
    code: 'kr',
    asset_url: countryFlagAssetUrl('kr'),
  },
  kw: {
    name: 'Kuwait', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.kuwait',
    dial_code: '+965',
    code: 'kw',
    asset_url: countryFlagAssetUrl('kw'),
  },
  kg: {
    name: 'Kyrgyzstan', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.kyrgyzstan',
    dial_code: '+996',
    code: 'kg',
    asset_url: countryFlagAssetUrl('kg'),
  },
  la: {
    name: 'Laos', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.laos',
    dial_code: '+856',
    code: 'la',
    asset_url: countryFlagAssetUrl('la'),
  },
  lv: {
    name: 'Latvia', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.latvia',
    dial_code: '+371',
    code: 'lv',
    asset_url: countryFlagAssetUrl('lv'),
  },
  lb: {
    name: 'Lebanon', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.lebanon',
    dial_code: '+961',
    code: 'lb',
    asset_url: countryFlagAssetUrl('lb'),
  },
  ls: {
    name: 'Lesotho', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.lesotho',
    dial_code: '+266',
    code: 'ls',
    asset_url: countryFlagAssetUrl('ls'),
  },
  lr: {
    name: 'Liberia', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.liberia',
    dial_code: '+231',
    code: 'lr',
    asset_url: countryFlagAssetUrl('lr'),
  },
  ly: {
    name: 'Libyan Arab Jamahiriya', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.libyan_arab_jamahiriya',
    dial_code: '+218',
    code: 'ly',
    asset_url: countryFlagAssetUrl('ly'),
  },
  li: {
    name: 'Liechtenstein', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.liechtenstein',
    dial_code: '+423',
    code: 'li',
    asset_url: countryFlagAssetUrl('li'),
  },
  lt: {
    name: 'Lithuania', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.lithuania',
    dial_code: '+370',
    code: 'lt',
    asset_url: countryFlagAssetUrl('lt'),
  },
  lu: {
    name: 'Luxembourg', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.luxembourg',
    dial_code: '+352',
    code: 'lu',
    asset_url: countryFlagAssetUrl('lu'),
  },
  mo: {
    name: 'Macao', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.macao',
    dial_code: '+853',
    code: 'mo',
    asset_url: countryFlagAssetUrl('mo'),
  },
  mk: {
    name: 'Macedonia', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.macedonia',
    dial_code: '+389',
    code: 'mk',
    asset_url: countryFlagAssetUrl('mk'),
  },
  mg: {
    name: 'Madagascar', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.madagascar',
    dial_code: '+261',
    code: 'mg',
    asset_url: countryFlagAssetUrl('mg'),
  },
  mw: {
    name: 'Malawi', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.malawi',
    dial_code: '+265',
    code: 'mw',
    asset_url: countryFlagAssetUrl('mw'),
  },
  my: {
    name: 'Malaysia', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.malaysia',
    dial_code: '+60',
    code: 'my',
    asset_url: countryFlagAssetUrl('my'),
  },
  mv: {
    name: 'Maldives', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.maldives',
    dial_code: '+960',
    code: 'mv',
    asset_url: countryFlagAssetUrl('mv'),
  },
  ml: {
    name: 'Mali', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.mali',
    dial_code: '+223',
    code: 'ml',
    asset_url: countryFlagAssetUrl('ml'),
  },
  mt: {
    name: 'Malta', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.malta',
    dial_code: '+356',
    code: 'mt',
    asset_url: countryFlagAssetUrl('mt'),
  },
  mh: {
    name: 'Marshall Islands', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.marshall_islands',
    dial_code: '+692',
    code: 'mh',
    asset_url: countryFlagAssetUrl('mh'),
  },
  mr: {
    name: 'Mauritania', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.mauritania',
    dial_code: '+222',
    code: 'mr',
    asset_url: countryFlagAssetUrl('mr'),
  },
  mu: {
    name: 'Mauritius', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.mauritius',
    dial_code: '+230',
    code: 'mu',
    asset_url: countryFlagAssetUrl('mu'),
  },
  mx: {
    name: 'Mexico', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.mexico',
    dial_code: '+52',
    code: 'mx',
    asset_url: countryFlagAssetUrl('mx'),
  },
  fm: {
    name: 'Micronesia, Federated States of Micronesia', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.micronesia_federated_states_of_micronesia',
    dial_code: '+691',
    code: 'fm',
    asset_url: countryFlagAssetUrl('fm'),
  },
  md: {
    name: 'Moldova', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.moldova',
    dial_code: '+373',
    code: 'md',
    asset_url: countryFlagAssetUrl('md'),
  },
  mc: {
    name: 'Monaco', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.monaco',
    dial_code: '+377',
    code: 'mc',
    asset_url: countryFlagAssetUrl('mc'),
  },
  mn: {
    name: 'Mongolia', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.mongolia',
    dial_code: '+976',
    code: 'mn',
    asset_url: countryFlagAssetUrl('mn'),
  },
  me: {
    name: 'Montenegro', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.montenegro',
    dial_code: '+382',
    code: 'me',
    asset_url: countryFlagAssetUrl('me'),
  },
  ms: {
    name: 'Montserrat', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.montserrat',
    dial_code: '+1664',
    code: 'ms',
    asset_url: countryFlagAssetUrl('ms'),
  },
  ma: {
    name: 'Morocco', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.morocco',
    dial_code: '+212',
    code: 'ma',
    asset_url: countryFlagAssetUrl('ma'),
  },
  mz: {
    name: 'Mozambique', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.mozambique',
    dial_code: '+258',
    code: 'mz',
    asset_url: countryFlagAssetUrl('mz'),
  },
  mm: {
    name: 'Myanmar', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.myanmar',
    dial_code: '+95',
    code: 'mm',
    asset_url: countryFlagAssetUrl('mm'),
  },
  na: {
    name: 'Namibia', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.namibia',
    dial_code: '+264',
    code: 'na',
    asset_url: countryFlagAssetUrl('na'),
  },
  nr: {
    name: 'Nauru', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.nauru',
    dial_code: '+674',
    code: 'nr',
    asset_url: countryFlagAssetUrl('nr'),
  },
  np: {
    name: 'Nepal', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.nepal',
    dial_code: '+977',
    code: 'np',
    asset_url: countryFlagAssetUrl('np'),
  },
  nl: {
    name: 'Netherlands', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.netherlands',
    dial_code: '+31',
    code: 'nl',
    asset_url: countryFlagAssetUrl('nl'),
  },
  an: {
    name: 'Netherlands Antilles', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.netherlands_antilles',
    dial_code: '+599',
    code: 'an',
    asset_url: countryFlagAssetUrl('an'),
  },
  nc: {
    name: 'New Caledonia', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.new_caledonia',
    dial_code: '+687',
    code: 'nc',
    asset_url: countryFlagAssetUrl('nc'),
  },
  nz: {
    name: 'New Zealand', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.new_zealand',
    dial_code: '+64',
    code: 'nz',
    asset_url: countryFlagAssetUrl('nz'),
  },
  ni: {
    name: 'Nicaragua', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.nicaragua',
    dial_code: '+505',
    code: 'ni',
    asset_url: countryFlagAssetUrl('ni'),
  },
  ne: {
    name: 'Niger', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.niger',
    dial_code: '+227',
    code: 'ne',
    asset_url: countryFlagAssetUrl('ne'),
  },
  ng: {
    name: 'Nigeria', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.nigeria',
    dial_code: '+234',
    code: 'ng',
    asset_url: countryFlagAssetUrl('ng'),
  },
  nu: {
    name: 'Niue', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.niue',
    dial_code: '+683',
    code: 'nu',
    asset_url: countryFlagAssetUrl('nu'),
  },
  nf: {
    name: 'Norfolk Island', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.norfolk_island',
    dial_code: '+672',
    code: 'nf',
    asset_url: countryFlagAssetUrl('nf'),
  },
  no: {
    name: 'Norway', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.norway',
    dial_code: '+47',
    code: 'no',
    asset_url: countryFlagAssetUrl('no'),
  },
  om: {
    name: 'Oman', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.oman',
    dial_code: '+968',
    code: 'om',
    asset_url: countryFlagAssetUrl('om'),
  },
  pk: {
    name: 'Pakistan', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.pakistan',
    dial_code: '+92',
    code: 'pk',
    asset_url: countryFlagAssetUrl('pk'),
  },
  pw: {
    name: 'Palau', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.palau',
    dial_code: '+680',
    code: 'pw',
    asset_url: countryFlagAssetUrl('pw'),
  },
  ps: {
    name: 'Palestinian Territory, Occupied', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.palestinian_territory_occupied',
    dial_code: '+970',
    code: 'ps',
    asset_url: countryFlagAssetUrl('ps'),
  },
  pa: {
    name: 'Panama', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.panama',
    dial_code: '+507',
    code: 'pa',
    asset_url: countryFlagAssetUrl('pa'),
  },
  pg: {
    name: 'Papua New Guinea', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.papua_new_guinea',
    dial_code: '+675',
    code: 'pg',
    asset_url: countryFlagAssetUrl('pg'),
  },
  py: {
    name: 'Paraguay', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.paraguay',
    dial_code: '+595',
    code: 'py',
    asset_url: countryFlagAssetUrl('py'),
  },
  pe: {
    name: 'Peru', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.peru',
    dial_code: '+51',
    code: 'pe',
    asset_url: countryFlagAssetUrl('pe'),
  },
  ph: {
    name: 'Philippines', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.philippines',
    dial_code: '+63',
    code: 'ph',
    asset_url: countryFlagAssetUrl('ph'),
  },
  pl: {
    name: 'Poland', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.poland',
    dial_code: '+48',
    code: 'pl',
    asset_url: countryFlagAssetUrl('pl'),
  },
  pt: {
    name: 'Portugal', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.portugal',
    dial_code: '+351',
    code: 'pt',
    asset_url: countryFlagAssetUrl('pt'),
  },
  pr: {
    name: 'Puerto Rico', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.puerto_rico',
    dial_code: '+1939',
    code: 'pr',
    asset_url: countryFlagAssetUrl('pr'),
  },
  qa: {
    name: 'Qatar', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.qatar',
    dial_code: '+974',
    code: 'qa',
    asset_url: countryFlagAssetUrl('qa'),
  },
  ro: {
    name: 'Romania', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.romania',
    dial_code: '+40',
    code: 'ro',
    asset_url: countryFlagAssetUrl('ro'),
  },
  ru: {
    name: 'Russia', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.russia',
    dial_code: '+7',
    code: 'ru',
    asset_url: countryFlagAssetUrl('ru'),
  },
  rw: {
    name: 'Rwanda', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.rwanda',
    dial_code: '+250',
    code: 'rw',
    asset_url: countryFlagAssetUrl('rw'),
  },
  kn: {
    name: 'Saint Kitts and Nevis', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.saint_kitts_and_nevis',
    dial_code: '+1869',
    code: 'kn',
    asset_url: countryFlagAssetUrl('kn'),
  },
  lc: {
    name: 'Saint Lucia', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.saint_lucia',
    dial_code: '+1758',
    code: 'lc',
    asset_url: countryFlagAssetUrl('lc'),
  },
  vc: {
    name: 'Saint Vincent and the Grenadines', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.saint_vincent_and_the_grenadines',
    dial_code: '+1784',
    code: 'vc',
    asset_url: countryFlagAssetUrl('vc'),
  },
  ws: {
    name: 'Samoa', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.samoa',
    dial_code: '+685',
    code: 'ws',
    asset_url: countryFlagAssetUrl('ws'),
  },
  sm: {
    name: 'San Marino', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.san_marino',
    dial_code: '+378',
    code: 'sm',
    asset_url: countryFlagAssetUrl('sm'),
  },
  st: {
    name: 'Sao Tome and Principe', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.sao_tome_and_principe',
    dial_code: '+239',
    code: 'st',
    asset_url: countryFlagAssetUrl('st'),
  },
  sa: {
    name: 'Saudi Arabia', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.saudi_arabia',
    dial_code: '+966',
    code: 'sa',
    asset_url: countryFlagAssetUrl('sa'),
  },
  sn: {
    name: 'Senegal', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.senegal',
    dial_code: '+221',
    code: 'sn',
    asset_url: countryFlagAssetUrl('sn'),
  },
  rs: {
    name: 'Serbia', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.serbia',
    dial_code: '+381',
    code: 'rs',
    asset_url: countryFlagAssetUrl('rs'),
  },
  sc: {
    name: 'Seychelles', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.seychelles',
    dial_code: '+248',
    code: 'sc',
    asset_url: countryFlagAssetUrl('sc'),
  },
  sl: {
    name: 'Sierra Leone', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.sierra_leone',
    dial_code: '+232',
    code: 'sl',
    asset_url: countryFlagAssetUrl('sl'),
  },
  sg: {
    name: 'Singapore', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.singapore',
    dial_code: '+65',
    code: 'sg',
    asset_url: countryFlagAssetUrl('sg'),
  },
  sk: {
    name: 'Slovakia', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.slovakia',
    dial_code: '+421',
    code: 'sk',
    asset_url: countryFlagAssetUrl('sk'),
  },
  si: {
    name: 'Slovenia', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.slovenia',
    dial_code: '+386',
    code: 'si',
    asset_url: countryFlagAssetUrl('si'),
  },
  sb: {
    name: 'Solomon Islands', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.solomon_islands',
    dial_code: '+677',
    code: 'sb',
    asset_url: countryFlagAssetUrl('sb'),
  },
  so: {
    name: 'Somalia', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.somalia',
    dial_code: '+252',
    code: 'so',
    asset_url: countryFlagAssetUrl('so'),
  },
  za: {
    name: 'South Africa', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.south_africa',
    dial_code: '+27',
    code: 'za',
    asset_url: countryFlagAssetUrl('za'),
  },
  ss: {
    name: 'South Sudan', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.south_sudan',
    dial_code: '+211',
    code: 'ss',
    asset_url: countryFlagAssetUrl('ss'),
  },
  es: {
    name: 'Spain', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.spain',
    dial_code: '+34',
    code: 'es',
    asset_url: countryFlagAssetUrl('es'),
  },
  lk: {
    name: 'Sri Lanka', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.sri_lanka',
    dial_code: '+94',
    code: 'lk',
    asset_url: countryFlagAssetUrl('lk'),
  },
  sr: {
    name: 'Suriname', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.suriname',
    dial_code: '+597',
    code: 'sr',
    asset_url: countryFlagAssetUrl('sr'),
  },
  sz: {
    name: 'Swaziland', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.swaziland',
    dial_code: '+268',
    code: 'sz',
    asset_url: countryFlagAssetUrl('sz'),
  },
  se: {
    name: 'Sweden', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.sweden',
    dial_code: '+46',
    code: 'se',
    asset_url: countryFlagAssetUrl('se'),
  },
  ch: {
    name: 'Switzerland', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.switzerland',
    dial_code: '+41',
    code: 'ch',
    asset_url: countryFlagAssetUrl('ch'),
  },
  tw: {
    name: 'Taiwan', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.taiwan',
    dial_code: '+886',
    code: 'tw',
    asset_url: countryFlagAssetUrl('tw'),
  },
  tj: {
    name: 'Tajikistan', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.tajikistan',
    dial_code: '+992',
    code: 'tj',
    asset_url: countryFlagAssetUrl('tj'),
  },
  tz: {
    name: 'Tanzania, United Republic of Tanzania', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.tanzania_united_republic_of_tanzania',
    dial_code: '+255',
    code: 'tz',
    asset_url: countryFlagAssetUrl('tz'),
  },
  th: {
    name: 'Thailand', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.thailand',
    dial_code: '+66',
    code: 'th',
    asset_url: countryFlagAssetUrl('th'),
  },
  tl: {
    name: 'Timor-Leste', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.timor_leste',
    dial_code: '+670',
    code: 'tl',
    asset_url: countryFlagAssetUrl('tl'),
  },
  tg: {
    name: 'Togo', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.togo',
    dial_code: '+228',
    code: 'tg',
    asset_url: countryFlagAssetUrl('tg'),
  },
  tk: {
    name: 'Tokelau', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.tokelau',
    dial_code: '+690',
    code: 'tk',
    asset_url: countryFlagAssetUrl('tk'),
  },
  to: {
    name: 'Tonga', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.tonga',
    dial_code: '+676',
    code: 'to',
    asset_url: countryFlagAssetUrl('to'),
  },
  tt: {
    name: 'Trinidad and Tobago', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.trinidad_and_tobago',
    dial_code: '+1868',
    code: 'tt',
    asset_url: countryFlagAssetUrl('tt'),
  },
  tn: {
    name: 'Tunisia', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.tunisia',
    dial_code: '+216',
    code: 'tn',
    asset_url: countryFlagAssetUrl('tn'),
  },
  tr: {
    name: 'Turkey', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.turkey',
    dial_code: '+90',
    code: 'tr',
    asset_url: countryFlagAssetUrl('tr'),
  },
  tm: {
    name: 'Turkmenistan', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.turkmenistan',
    dial_code: '+993',
    code: 'tm',
    asset_url: countryFlagAssetUrl('tm'),
  },
  tc: {
    name: 'Turks and Caicos Islands', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.turks_and_caicos_islands',
    dial_code: '+1649',
    code: 'tc',
    asset_url: countryFlagAssetUrl('tc'),
  },
  tv: {
    name: 'Tuvalu', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.tuvalu',
    dial_code: '+688',
    code: 'tv',
    asset_url: countryFlagAssetUrl('tv'),
  },
  ug: {
    name: 'Uganda', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.uganda',
    dial_code: '+256',
    code: 'ug',
    asset_url: countryFlagAssetUrl('ug'),
  },
  ua: {
    name: 'Ukraine', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.ukraine',
    dial_code: '+380',
    code: 'ua',
    asset_url: countryFlagAssetUrl('ua'),
  },
  ae: {
    name: 'United Arab Emirates', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.united_arab_emirates',
    dial_code: '+971',
    code: 'ae',
    asset_url: countryFlagAssetUrl('ae'),
  },
  gb: {
    name: 'United Kingdom', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.united_kingdom',
    dial_code: '+44',
    code: 'gb',
    asset_url: countryFlagAssetUrl('gb'),
  },
  us: {
    name: 'United States', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.united_states',
    dial_code: '+1',
    code: 'us',
    asset_url: countryFlagAssetUrl('us'),
  },
  uy: {
    name: 'Uruguay', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.uruguay',
    dial_code: '+598',
    code: 'uy',
    asset_url: countryFlagAssetUrl('uy'),
  },
  uz: {
    name: 'Uzbekistan', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.uzbekistan',
    dial_code: '+998',
    code: 'uz',
    asset_url: countryFlagAssetUrl('uz'),
  },
  vu: {
    name: 'Vanuatu', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.vanuatu',
    dial_code: '+678',
    code: 'vu',
    asset_url: countryFlagAssetUrl('vu'),
  },
  ve: {
    name: 'Venezuela, Bolivarian Republic of Venezuela', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.venezuela_bolivarian_republic_of_venezuela',
    dial_code: '+58',
    code: 've',
    asset_url: countryFlagAssetUrl('ve'),
  },
  vn: {
    name: 'Vietnam', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.vietnam',
    dial_code: '+84',
    code: 'vn',
    asset_url: countryFlagAssetUrl('vn'),
  },
  vg: {
    name: 'Virgin Islands, British', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.virgin_islands_british',
    dial_code: '+1284',
    code: 'vg',
    asset_url: countryFlagAssetUrl('vg'),
  },
  wf: {
    name: 'Wallis and Futuna', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.wallis_and_futuna',
    dial_code: '+681',
    code: 'wf',
    asset_url: countryFlagAssetUrl('wf'),
  },
  ye: {
    name: 'Yemen', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.yemen',
    dial_code: '+967',
    code: 'ye',
    asset_url: countryFlagAssetUrl('ye'),
  },
  zm: {
    name: 'Zambia', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.zambia',
    dial_code: '+260',
    code: 'zm',
    asset_url: countryFlagAssetUrl('zm'),
  },
  zw: {
    name: 'Zimbabwe', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.phone-codes.zimbabwe',
    dial_code: '+263',
    code: 'zw',
    asset_url: countryFlagAssetUrl('zw'),
  },
};
