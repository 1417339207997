/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { EntityType } from 'embercom/models/data/entity-types';
import { AssignableEntity, AssigningEntity } from 'embercom/models/data/inbox/assignment-enums';
import { ChannelData, Channel } from 'embercom/models/data/inbox/channels';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import AdminSummary from 'embercom/objects/inbox/admin-summary';
import Conversation, { ConversationState } from 'embercom/objects/inbox/conversation';
import { type RenderableData } from 'embercom/objects/inbox/renderable-data';
import RenderablePart from 'embercom/objects/inbox/renderable-part';
import Assignment from 'embercom/objects/inbox/renderable/assignment';
import BotComment from 'embercom/objects/inbox/renderable/bot-comment';
import UserComment from 'embercom/objects/inbox/renderable/user-comment';
import type TeamSummary from 'embercom/objects/inbox/team-summary';
import UserSummary, { Role } from 'embercom/objects/inbox/user-summary';
import { random, times } from 'underscore';
import AttributeCollector from 'embercom/objects/inbox/renderable/attribute-collector';
import { AttributeCollectorType } from 'embercom/models/data/inbox/attribute-collector-enums';
import type ApplicationInstance from '@ember/application/instance';
import type IntlService from 'embercom/services/intl';
import { StateChangeReason, StateChangeType } from 'embercom/models/data/inbox/state-change-enums';
import TeammateStateChange from 'embercom/objects/inbox/renderable/state-changes/teammate-state-change';
import { DurationObject, DurationType } from 'embercom/objects/inbox/duration';
import moment from 'moment-timezone';
import CompanySummary from 'embercom/objects/inbox/company-summary';

function blocksForText(text: string) {
  return [{ text, type: 'paragraph', class: 'no-margin' }];
}

type Comment = [typeof BotComment | typeof UserComment, string];

type ConversationData = {
  title: string;
  userName: string;
  comments: Comment[];
};

function generateData(intl: IntlService, teammate: AdminSummary): ConversationData[] {
  return [
    {
      title: intl.t('inbox.onboarding.conversations.alissa.title'),
      userName: 'Alissa',
      comments: [
        [
          BotComment,
          intl.t('inbox.onboarding.conversations.alissa.comments.one', {
            firstName: teammate.firstName,
          }),
        ],
      ],
    },
    {
      title: 'Pausing subscription',
      userName: 'Anna',
      comments: [
        [UserComment, 'Hi there! I have a question!'],
        [UserComment, 'Can I pause my subscription?'],
      ],
    },
    {
      title: 'Question about subscription',
      userName: 'Richard',
      comments: [
        [UserComment, 'Could you please confirm what happens when we cancel our subscription?'],
        [UserComment, 'Do we lose access immediately?'],
      ],
    },
    {
      title: 'Discount',
      userName: 'Laura',
      comments: [[UserComment, 'Can I get a discount code?']],
    },
    {
      title: 'Shipping confirmation',
      userName: 'Paul',
      comments: [[UserComment, 'I want an update on my shipping dates.']],
    },
  ];
}

let getCompanyName = (id: string | number) => `Example Company ${id}`;

function generateEvents(
  teammate: AdminSummary,
  team: TeamSummary,
  operator: AdminSummary,
): RenderableData[] {
  let attributes = [
    {
      identifier: 'impact',
      name: 'Impact',
      type: 'string',
    },
  ];

  return [
    new Assignment(
      AssigningEntity.Bot,
      undefined,
      [{ entityType: AssignableEntity.UnassignedTeammate, entity: undefined }],
      [
        { entityType: AssignableEntity.Teammate, entity: teammate },
        { entityType: AssignableEntity.Team, entity: team },
      ],
      undefined,
    ),
    new AttributeCollector(attributes, AttributeCollectorType.BotWorkflow, operator),
    new TeammateStateChange(
      StateChangeType.NotPriority,
      StateChangeReason.StandardStateChange,
      teammate,
      { priority: false },
    ),
    new TeammateStateChange(
      StateChangeType.Snoozed,
      StateChangeReason.StandardStateChange,
      teammate,
      { duration: new DurationObject(DurationType.Tomorrow) },
    ),
    new TeammateStateChange(
      StateChangeType.Unsnoozed,
      StateChangeReason.StandardStateChange,
      teammate,
      {},
    ),
  ];
}

export function generateConversations(
  owner: ApplicationInstance,
  count: number,
  teammate: AdminSummary,
  team: TeamSummary,
) {
  let intl = owner.lookup('service:intl') as IntlService;
  let data = generateData(intl, teammate);

  return times(count, (n) => {
    let attrs = data[n];

    let userSummary = new UserSummary(
      n.toString(),
      `${attrs.userName.toLocaleLowerCase()}@example.org`,
      attrs.userName,
      undefined,
      new CompanySummary({ id: n.toString(), name: getCompanyName(n + 1) }),
      undefined,
      undefined,
      Role.User,
    );

    let adminSummary = new AdminSummary(n, 'Operator', null);

    let currentTime = moment().subtract(30, 'minute');

    let channel = new ChannelData(Channel.Email, Channel.Email);
    let parts = attrs.comments.map(([type, text]) => {
      return new RenderablePart(
        n,
        type === BotComment ? RenderableType.BotComment : RenderableType.UserComment,
        type === BotComment
          ? new BotComment(blocksForText(text), adminSummary)
          : new UserComment(blocksForText(text), userSummary, [], Channel.Email),
        currentTime.toDate(),
        1,
        EntityType.User,
      );
    });

    let conversation = new Conversation({
      id: n + 1,
      redacted: false,
      renderableParts: parts,
      userSummary,
      state: ConversationState.Open,
      priority: false,
      channel,
      attributes: [],
      title: attrs.title,
      adminAssignee: teammate,
      teamAssignee: team,
      participantSummaries: [userSummary],
      canReplyToUser: true,
      linkedConversationIds: null,
      linkedCustomerTicketIds: null,
    });

    if (n === 0) {
      let events = generateEvents(teammate, team, adminSummary);
      events.forEach((eventData) => {
        currentTime.add(random(2, 5), 'minute');
        conversation.addPendingPart(eventData, currentTime.toDate());
      });
    }

    return conversation;
  });
}
