/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */

import type {
  Block,
  BlockList,
  Html,
  Image,
  List,
  MessengerCardBlock,
} from '@intercom/interblocks.ts';
import { type SUPPORTED_LANGUAGES } from 'embercom/lib/ai-assist';

// Sets up prompts for the `open-ai-{key}` Cmd-K actions in the OpenAI demo. Currently only paragraph blocks.

export type PromptPrefix =
  | `translate-${(typeof SUPPORTED_LANGUAGES)[number]}`
  | 'friendly'
  | 'shorten'
  | 'formal'
  | 'expand'
  | 'rephrase'
  | 'auto-correct';

export class PromptData {
  promptKey: PromptPrefix;
  inputVariables: { [key: string]: string };
  constructor(promptKey: PromptPrefix, inputVariables: { [key: string]: string }) {
    this.promptKey = promptKey;
    this.inputVariables = inputVariables;
  }
}

let blocksToText = (blocks: BlockList) => {
  return blocks
    .filter((block) => block.type !== 'image')
    .map(blockToText)
    .filter(Boolean)
    .join('<br>');
};

let blocksToTextWithImages = (blocks: BlockList) => {
  return blocks.map(blockToText).filter(Boolean).join('<br>');
};

function blockToText(block: Block): string {
  switch (block.type) {
    case 'messengerCard':
      block = block as MessengerCardBlock;
      return `[${block.text}]`;
    case 'html':
      block = block as Html;
      return block.content;

    case 'unorderedList':
    case 'orderedList':
      block = block as List;
      return block.items.map((item, index) => `${index + 1}. ${item}`).join(' ');

    case 'image':
      block = block as Image;
      return imageBlockToText(block);
    default:
      if ('text' in block && textIsString(block.text)) {
        return block.text;
      } else {
        return '';
      }
  }
}

function textIsString(text: unknown): text is string {
  return typeof text === 'string';
}

function imageBlockToText(block: Image) {
  return `[Image: ${block.url}]`;
}

function imageBlockMapping(blocks: BlockList) {
  let imageBlocks: Record<string, Image> = {};
  blocks
    .filter((block) => block.type === 'image')
    .forEach((block) => {
      block = block as Image;
      imageBlocks[imageBlockToText(block)] = block;
    });
  return imageBlocks;
}

let getOpenAIPrompt = (
  promptKey: PromptPrefix,
  composerBlocks: BlockList,
  keepImages?: boolean,
) => {
  let blocksText;
  if (keepImages) {
    blocksText = blocksToTextWithImages(composerBlocks);
  } else {
    blocksText = blocksToText(composerBlocks);
  }
  return new PromptData(promptKey, { text: blocksText });
};

export { blocksToText, blocksToTextWithImages, imageBlockMapping, getOpenAIPrompt };
