/* RESPONSIBLE TEAM: team-tickets-1 */
import type TicketCustomState from 'embercom/objects/inbox/ticket-custom-state';
import type Conversation from 'embercom/objects/inbox/conversation';
import { ConversationState, TicketSystemState } from 'embercom/objects/inbox/conversation';
import { NamedIcon } from 'embercom/objects/inbox/command-k/icons';
import { HotkeyID } from 'embercom/services/inbox-hotkeys/HotkeyID';
import { type UpdateTicketStateCommandKContext } from 'embercom/objects/inbox/command-k/update-ticket-state';
import type IntlService from 'embercom/services/intl';
import { type ChildAction } from 'embercom/objects/inbox/command-k/action';
import type TicketStateService from 'embercom/services/ticket-state-service';

// Takes a list of customStates and returns a list of dropdown items for the CmdK

export function buildUpdateTicketStateCommandKContext({
  customStates,
  selectedCustomStateId,
  allowClosedState = false,
  intl,
  conversation = undefined,
}: {
  customStates: TicketCustomState[];
  selectedCustomStateId?: number;
  allowClosedState: boolean;
  intl: IntlService;
  conversation?: Partial<Conversation> | undefined;
}): UpdateTicketStateCommandKContext {
  let validCustomStates = customStates
    .filter((ticketCustomState) => ticketCustomState.systemState !== TicketSystemState.Submitted)
    .filter((ticketCustomState) => ticketCustomState.id !== selectedCustomStateId)
    .map((state) => {
      return {
        id: state.id,
        state: state.id,
        label: localisedCustomStateAdminLabel(state.adminLabel, intl),
        iconComponent: 'inbox2/ticket-state-dot-icon' as const,
        systemState: state.systemState,
      };
    });

  if (!allowClosedState) {
    return {
      states: [...validCustomStates],
      conversation,
    } as UpdateTicketStateCommandKContext;
  }

  let closedConversationState = {
    state: ConversationState.Closed,
    label: intl.t('inbox.conversation-states.close'),
    icon: new NamedIcon('close-conversation'),
    shortcutId: HotkeyID.Close,
  };

  return {
    states: [...validCustomStates, closedConversationState],
    conversation,
  };
}

function getTicketSystemState(
  action: ChildAction,
  ticketStateService: TicketStateService,
): TicketSystemState {
  return ticketStateService.getTicketCustomStateById(action.value)?.systemState;
}

export function groupCustomStatesBySystemState(
  customStates: Array<ChildAction>,
  ticketStateService: TicketStateService,
): Array<ChildAction> {
  // Group by system state
  let customStatesGroupedBySystemState: { [key: string]: Array<ChildAction> } = customStates.reduce(
    (groups: { [key: string]: Array<ChildAction> }, item: ChildAction) => {
      let state: string = getTicketSystemState(item, ticketStateService);
      if (!groups[state]) {
        groups[state] = [];
      }
      groups[state].push(item);
      return groups;
    },
    {},
  );

  // Flatten the sorted groups into a single array
  return Object.values(customStatesGroupedBySystemState).flat();
}

export function localisedCustomStateAdminLabel(adminLabel: string, intl: IntlService): string {
  switch (adminLabel) {
    case 'Submitted':
      return intl.t(`inbox.ticket-state.${TicketSystemState.Submitted}`);
    case 'In progress':
      return intl.t(`inbox.ticket-state.${TicketSystemState.InProgress}`);
    case 'Waiting on customer':
      return intl.t(`inbox.ticket-state.${TicketSystemState.WaitingOnCustomer}`);
    case 'Resolved':
      return intl.t(`inbox.ticket-state.${TicketSystemState.Resolved}`);
    default:
      return adminLabel;
  }
}
