/* RESPONSIBLE TEAM: team-purchase-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import {
  CORE_GOOD_ID,
  ARTICLES_ESSENTIAL_ID,
  ARTICLES_ID,
  ARTICLES_PRO_ID,
  CORE_ACCELERATE_ID,
  CORE_GROW_ID,
  CORE_CONVERT_ID,
  CORE_CONVERT_PRO_ID,
  CORE_ENGAGE_ID,
  CORE_ENGAGE_PREMIUM_ID,
  CORE_ENGAGE_PRO_ID,
  CORE_ID,
  CORE_STARTER_ID,
  CORE_SCALE_ID,
  CORE_SUPPORT_ID,
  CORE_SUPPORT_PREMIUM_ID,
  CORE_SUPPORT_PRO_ID,
  INBOX_ESSENTIAL_ID,
  INBOX_ID,
  INBOX_PREMIUM_ID,
  INBOX_PRO_ID,
  MESSAGES_ESSENTIAL_ID,
  MESSAGES_ID,
  MESSAGES_PREMIUM_ID,
  MESSAGES_PRO_ID,
  CORE_STARTER_BASE_ID,
} from 'embercom/lib/billing';
import { PRODUCT_FEATURES } from 'embercom/lib/intershop/intershop-products';
import {
  type IntershopProductCardData,
  type IntershopProductPageData,
} from 'embercom/models/data/intershop/product-types';
import { type IntershopPlanCardData } from 'embercom/models/data/intershop/plan-types';
import {
  INCLUDED_ADD_ON_PRODUCTS,
  addonCardData,
  addonData,
  categorizeAddons,
  includedAddOnProductIds,
} from 'embercom/lib/intershop/intershop-addons';
import { TierPriceFormat, type Tier, type Block } from 'embercom/models/data/pricing/tier-types';
import { Metric } from 'embercom/models/data/pricing/metric-types';
import type PricingMetric from 'embercom/lib/purchase/pricing-metric';

const INCLUDED_CORE_PRODUCTS = [
  INBOX_ID,
  MESSAGES_ID,
  ARTICLES_ID,
  CORE_ID,
  CORE_STARTER_ID,
  CORE_SUPPORT_ID,
  CORE_ENGAGE_ID,
  CORE_CONVERT_ID,
];

function includedCoreProductIds(): Array<string> {
  return [...INCLUDED_CORE_PRODUCTS];
}

function includedProductIds(): Array<string> {
  return [...INCLUDED_CORE_PRODUCTS, ...INCLUDED_ADD_ON_PRODUCTS];
}

type PlanId = string;
type ProductId = string;

const excludedPlansSalesforceContracted = (pricingModelIdentifier: string): Array<string> => {
  // per_product_pricing_high and per_product_pricing_with_seats should not show premium plans
  if (
    pricingModelIdentifier === 'per_product_pricing_high' ||
    pricingModelIdentifier === 'per_product_pricing_with_seats'
  ) {
    return [INBOX_PREMIUM_ID, MESSAGES_PREMIUM_ID];
  }

  // for VBP 1.X, we want to exclude Start for salesforce contracted customers
  return [CORE_GOOD_ID];
};

const excludedPlansNonSalesforceContracted = (pricingModelIdentifier: string): Array<string> => {
  // per_product_pricing_high and per_product_pricing_with_seats should not show premium plans
  if (
    pricingModelIdentifier === 'per_product_pricing_high' ||
    pricingModelIdentifier === 'per_product_pricing_with_seats'
  ) {
    return [INBOX_PREMIUM_ID, MESSAGES_PREMIUM_ID];
  }

  // for VBP 1.X, we want to exclude Scale for non-salesforce contracted customers
  return [CORE_SCALE_ID];
};

const PLAN_CARD_DATA: Record<PlanId, IntershopPlanCardData> = {
  [INBOX_ESSENTIAL_ID]: {
    nameWithProduct: `intershop.product_plan.plans.inbox_essential.name_with_product`,
    description: `intershop.product_plan.plans.inbox_essential.description`,
  },
  [INBOX_PRO_ID]: {
    nameWithProduct: `intershop.product_plan.plans.inbox_pro.name_with_product`,
    description: `intershop.product_plan.plans.inbox_pro.description`,
  },
  [INBOX_PREMIUM_ID]: {
    nameWithProduct: `intershop.product_plan.plans.inbox_premium.name_with_product`,
    description: `intershop.product_plan.plans.inbox_premium.description`,
  },
  [MESSAGES_ESSENTIAL_ID]: {
    nameWithProduct: `intershop.product_plan.plans.messages_essential.name_with_product`,
    description: `intershop.product_plan.plans.messages_essential.description`,
  },
  [MESSAGES_PRO_ID]: {
    nameWithProduct: `intershop.product_plan.plans.messages_pro.name_with_product`,
    description: `intershop.product_plan.plans.messages_pro.description`,
  },
  [MESSAGES_PREMIUM_ID]: {
    nameWithProduct: `intershop.product_plan.plans.messages_premium.name_with_product`,
    description: `intershop.product_plan.plans.messages_premium.description`,
  },
  [ARTICLES_ESSENTIAL_ID]: {
    nameWithProduct: `intershop.product_plan.plans.articles_essential.name_with_product`,
    description: `intershop.product_plan.plans.articles_essential.description`,
  },
  [ARTICLES_PRO_ID]: {
    nameWithProduct: `intershop.product_plan.plans.articles_pro.name_with_product`,
    description: `intershop.product_plan.plans.articles_pro.description`,
  },
  [CORE_GOOD_ID]: {
    nameWithProduct: `intershop.product_plan.plans.core_good.name_with_product`,
    description: `intershop.product_plan.plans.core_good.description`,
    planSvg: 'upgrade/starter',
    features: ['intershop.product_plan.plans.core_good.features.1'],
  },
  [CORE_GROW_ID]: {
    nameWithProduct: `intershop.product_plan.plans.core_better.name_with_product`,
    description: `intershop.product_plan.plans.core_better.description`,
    planSvg: 'upgrade/grow',
    features: [
      'intershop.product_plan.plans.core_better.features.1',
      'intershop.product_plan.plans.core_better.features.2',
      'intershop.product_plan.plans.core_better.features.3',
      'intershop.product_plan.plans.core_better.features.4',
      'intershop.product_plan.plans.core_better.features.5',
    ],
    prefix: 'intershop.product_plan.plans.core_better.prefix',
  },
  [CORE_ACCELERATE_ID]: {
    nameWithProduct: `intershop.product_plan.plans.core_best.name_with_product`,
    description: `intershop.product_plan.plans.core_best.description`,
    planSvg: 'upgrade/accelerate',
    features: [
      'intershop.product_plan.plans.core_best.features.1',
      'intershop.product_plan.plans.core_best.features.2',
      'intershop.product_plan.plans.core_best.features.3',
      'intershop.product_plan.plans.core_best.features.4',
      'intershop.product_plan.plans.core_best.features.5',
    ],
    prefix: 'intershop.product_plan.plans.core_best.prefix',
  },
  [CORE_SCALE_ID]: {
    nameWithProduct: `intershop.product_plan.plans.core_premium.name_with_product`,
    description: `intershop.product_plan.plans.core_premium.description`,
    planSvg: 'upgrade/scale',
    features: [
      'intershop.product_plan.plans.core_premium.features.1',
      'intershop.product_plan.plans.core_premium.features.2',
      'intershop.product_plan.plans.core_premium.features.3',
      'intershop.product_plan.plans.core_premium.features.4',
      'intershop.product_plan.plans.core_premium.features.5',
      'intershop.product_plan.plans.core_premium.features.6',
    ],
    prefix: 'intershop.product_plan.plans.core_premium.prefix',
  },

  [CORE_STARTER_BASE_ID]: {
    nameWithProduct: `intershop.product_plan.plans.core_starter.name_with_product`,
    description: `intershop.product_plan.plans.core_starter.description`,
  },
  [CORE_SUPPORT_PRO_ID]: {
    nameWithProduct: `intershop.product_plan.plans.core_support_pro.name_with_product`,
    description: `intershop.product_plan.plans.core_support_pro.description`,
  },
  [CORE_SUPPORT_PREMIUM_ID]: {
    nameWithProduct: `intershop.product_plan.plans.core_support_premium.name_with_product`,
    description: `intershop.product_plan.plans.core_support_premium.description`,
  },
  [CORE_ENGAGE_PRO_ID]: {
    nameWithProduct: `intershop.product_plan.plans.core_engage_pro.name_with_product`,
    description: `intershop.product_plan.plans.core_engage_pro.description`,
  },
  [CORE_ENGAGE_PREMIUM_ID]: {
    nameWithProduct: `intershop.product_plan.plans.core_engage_premium.name_with_product`,
    description: `intershop.product_plan.plans.core_engage_premium.description`,
  },
  [CORE_CONVERT_PRO_ID]: {
    nameWithProduct: `intershop.product_plan.plans.core_convert_pro.name_with_product`,
    description: `intershop.product_plan.plans.core_convert_pro.description`,
  },
};

const FIXED_DISCOUNT_OFFERED = 8.0;

const planCardData = (planId: string) => PLAN_CARD_DATA[planId];

const PRODUCT_CARD_FEATURES = {
  inbox: {
    icon: 'inbox',
    name: 'Inbox',
  },
  outbound: {
    icon: 'manual-message',
    name: 'Outbound messages',
  },
  bots: {
    icon: 'operator',
    name: 'Operator bots',
  },
  articles: {
    icon: 'article',
    name: 'Articles',
  },
  shared_inbox: {
    icon: 'inbox',
    name: 'Shared Inbox',
  },
  messenger: {
    icon: 'messenger',
    name: 'Messenger',
  },
};

const PRODUCT_CARD_DATA: Record<ProductId, IntershopProductCardData> = {
  [INBOX_ID]: {
    productImage: 'products/2020/July/inbox',
    shortDescription: 'intershop.product_card.inbox.short_description',
    features: [],
  },
  [MESSAGES_ID]: {
    productImage: 'products/2020/July/outbound',
    productNameOverride: 'Outbound messages',
    shortDescription: 'intershop.product_card.outbound_messages.short_description',
    features: [],
  },
  [ARTICLES_ID]: {
    productImage: 'products/2020/July/articles',
    shortDescription: 'intershop.product_card.articles.short_description',
    features: [],
  },
  [CORE_STARTER_ID]: {
    productImage: 'upgrade/support',
    shortDescription: 'intershop.product_card.starter.short_description',
    features: [PRODUCT_CARD_FEATURES.shared_inbox, PRODUCT_CARD_FEATURES.messenger],
    complementaryDescription: 'intershop.product_card.starter.complementary_description',
    brandColor: '#7ee6e6',
  },
  [CORE_SUPPORT_ID]: {
    productImage: 'upgrade/support',
    shortDescription: 'intershop.product_card.support.short_description',
    features: [PRODUCT_CARD_FEATURES.inbox, PRODUCT_CARD_FEATURES.articles],
    complementaryDescription: 'intershop.product_card.support.complementary_description',
    brandColor: '#f2a456',
  },
  [CORE_ENGAGE_ID]: {
    productImage: 'upgrade/engage',
    shortDescription: 'intershop.product_card.engage.short_description',
    features: [PRODUCT_CARD_FEATURES.outbound],
    complementaryDescription: 'intershop.product_card.engage.complementary_description',
    brandColor: '#ed6e65',
  },
  [CORE_CONVERT_ID]: {
    productImage: 'upgrade/marketing',
    shortDescription: 'intershop.product_card.convert.short_description',
    features: [PRODUCT_CARD_FEATURES.bots],
    complementaryDescription: 'intershop.product_card.convert.complementary_description',
    brandColor: '#5367bc',
  },
};

function productCardData(productId: ProductId): IntershopProductCardData {
  return PRODUCT_CARD_DATA[productId];
}

const PRODUCT_PAGE_DATA: Record<ProductId, IntershopProductPageData> = {
  [INBOX_ID]: {
    heading: 'intershop.product_page.heading.inbox',
  },
  [MESSAGES_ID]: {
    heading: 'intershop.product_page.heading.messages',
  },
  [ARTICLES_ID]: {
    heading: 'intershop.product_page.heading.articles',
    description: {
      content: 'intershop.product_page.description.content.articles',
    },
  },
  [CORE_ID]: {
    heading: 'intershop.product_page.heading.core',
  },
  [CORE_STARTER_ID]: {
    heading: 'intershop.product_page.heading.core_starter',
    description: {
      content: 'intershop.product_page.description.content.core_starter',
    },
  },
  [CORE_SUPPORT_ID]: {
    heading: 'intershop.product_page.heading.core_support',
    description: {
      content: 'intershop.product_page.description.content.core_support',
    },
  },
  [CORE_ENGAGE_ID]: {
    heading: 'intershop.product_page.heading.core_engage',
    description: {
      content: 'intershop.product_page.description.content.core_engage',
    },
  },
  [CORE_CONVERT_ID]: {
    heading: 'intershop.product_page.heading.core_convert',
    description: {
      content: 'intershop.product_page.description.content.core_convert',
    },
  },
};

function productPageData(productId: ProductId): IntershopProductPageData {
  return PRODUCT_PAGE_DATA[productId];
}

// blocks are tiers but using block size for start and end size
function generateBlocksFromTiers(pricingMetric: PricingMetric, usageCap: number): Array<Block> {
  let blocks = new Array<Block>();
  let blockSize = pricingMetric.blockSize;

  // resolutions on all but VBP 2.2 don't have tiers and are treated more like a seat metric
  // Therefore we need to create a mock list of tiers
  let tiers: Array<Tier> = pricingMetric.tiers;

  // we need the lowest selectable tier for resolutions to be 0
  if (pricingMetric.metric === Metric.resolutions) {
    tiers = [
      {
        id: 0,
        ending_unit: 0,
        price: 0,
        price_format: TierPriceFormat.FlatFee,
        starting_unit: 0,
      },
      ...pricingMetric.tiers,
    ];
  }

  if (pricingMetric.metric === Metric.resolutions && pricingMetric.tiers.length === 0) {
    tiers = [
      {
        id: 0,
        ending_unit: 0,
        price: 0,
        price_format: TierPriceFormat.FlatFee,
        starting_unit: 0,
      },
      {
        id: 1,
        ending_unit: 9999999999,
        price: pricingMetric.perUnitPrice,
        price_format: TierPriceFormat.PerUnit,
        starting_unit: 0,
      },
    ];
  }

  for (let i = 0; i < tiers.length; i++) {
    let tier = tiers[i];
    let previousBlock = blocks[blocks.length - 1];
    let cumulativePrice = previousBlock?.cumulative_price ?? 0;
    let previousEndingUnit = previousBlock?.ending_unit ?? 0;
    let isFinalTier = tier === tiers.lastObject;
    if (tier.price_format === TierPriceFormat.FlatFee && tier.ending_unit < usageCap) {
      blocks.push(calculateBlockFromFlatFeeTier(tier, cumulativePrice));
    } else if (tier.price_format === TierPriceFormat.PerUnit) {
      blocks.push(
        ...calculateBlocksFromPerUnitTier(
          tier,
          previousEndingUnit,
          cumulativePrice,
          blockSize,
          isFinalTier,
          usageCap,
        ),
      );
    }
  }

  return blocks;
}

function calculateBlockFromFlatFeeTier(tier: Tier, cumulativePrice: number): Block {
  return {
    ...tier,
    cumulative_price: tier.price + cumulativePrice,
  };
}

function calculateBlocksFromPerUnitTier(
  tier: Tier,
  previousEndingUnit: number,
  cumulativePrice: number,
  blockSize: number,
  isFinalTier: boolean,
  usageCap: number,
): Array<Block> {
  let price = cumulativePrice;
  let pricePerBlock = tier.price * blockSize;
  // if final tier ending unit is lower than usage cap, use usage cap instead
  let tierEndingUnit = isFinalTier && tier.ending_unit < usageCap ? usageCap : tier.ending_unit;
  let blocks = new Array<Block>();
  for (let i = previousEndingUnit; i < tierEndingUnit && i < usageCap; i += blockSize) {
    price += pricePerBlock;
    blocks.push({
      ...tier,
      starting_unit: i + 1, // add 1 to the previous block's ending unit to get starting unit
      ending_unit: i + blockSize,
      cumulative_price: price,
    });
  }

  return blocks;
}

export {
  addonCardData,
  planCardData,
  addonData,
  categorizeAddons,
  productCardData,
  productPageData,
  PRODUCT_FEATURES,
  FIXED_DISCOUNT_OFFERED,
  excludedPlansSalesforceContracted,
  excludedPlansNonSalesforceContracted,
  includedProductIds,
  includedCoreProductIds,
  includedAddOnProductIds,
  generateBlocksFromTiers,
};
