/* RESPONSIBLE TEAM: team-help-desk-ai */

// Defines the breakpoints for Copilot in the sidebar
// Closed is 0, Narrow is 1 -> 300, Default is 300+
export const CLOSED = 0;
export const NARROW = 1;
export const DEFAULT = 300;

export const Breakpoints = {
  narrow: NARROW,
  default: DEFAULT,
  closed: CLOSED,
};

export function isElementNarrow(elementWidth: number): boolean {
  return elementWidth <= Breakpoints.default;
}
