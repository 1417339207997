/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
export default {
  title: 'Do you want to save?',
  primaryButtonType: 'primary',
  confirmButtonText: 'Save changes',
  cancelButtonText: "Don't save",
  body: "You'll lose your changes if you don't save them.",
  distinguishCancelFromClose: true,
};
